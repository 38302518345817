// Includes scoping for the ContentTabs component
.visit-table {
  @include containerPrimary;

  .wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 720px;
    padding-bottom: 50px;
    margin: 0 auto;

    @include respond($break80) {
      flex-direction: column;
    }
  }

  .hours {
    min-width: 214px;
    margin-right: 60px;

    // Hides panel overflow
    overflow: hidden;

    .tab-nav {
      position: relative;
      max-width: 156px;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 20px;

      .tab-marker {
        position: absolute;
        bottom: 0;
        height: 1px;
        background-color: $neutral100;
        transition:
          width 0.1s $timing,
          left 0.2s $timing;
      }

      ul {
        justify-content: space-between;
        width: 100%;
        padding-bottom: 11px;
      }

      li {
        flex: 0 0 auto;

        &.active a {
          color: $neutralBlack;
        }

        a {
          @include buttonUnstyled;
          padding-bottom: 11px;
          font-size: 18px;
          font-weight: $book;
          color: $neutral60;
          transition: color 0.2s $timing;

          &.active {
            color: $neutralBlack;
          }
        }
      }
    }

    .today {
      font-size: 40px;
      font-weight: $light;

      @include respond($break80) {
        font-size: 32px;
      }
    }

    .week {
      display: none;
    }

    // &.center {
    //   margin: 0 auto;
    //
    //   .panels {
    //     width: 400px;
    //   }
    //
    //   td:nth-child(2) {
    //     text-align: right;
    //   }
    // }

    .day-mobile {
      display: none;
    }
  }

  .prices {
    min-width: 420px;

    // &.center {
    //   td:nth-child(2) {
    //     text-align: right;
    //   }
    // }
  }

  .center {
    margin: 0 auto;

    .panels {
      width: 400px;
    }

    td:nth-child(2) {
      text-align: right;
    }
  }

  .hours-mobile {
    display: none;
  }

  // bundling these together since all these
  // changes happen at the same time
  @include respond($break80) {
    .wrapper {
      padding-bottom: 0;
    }

    .hours {
      // &.center {
      //   margin: 0;
      // }

      .tab-nav {
        margin-bottom: 0;

        ul {
          border-bottom: none;
        }

        li {
          &:not(:first-child) {
            display: none;
          }

          a {
            font-weight: $semibold;
          }
        }

        .tab-marker {
          display: none;
        }
      }

      .day-mobile {
        display: block;

        h4 {
          padding-bottom: 0.375em;
          font-size: 18px;
          font-weight: $semibold;
        }
      }

      .day-desktop {
        display: none;
      }
    }

    .center {
      margin: 0;
    }

    .prices {
      min-width: 0;
      margin-top: 20px;
    }

    .panels {
      .placeholder-list {
        display: none;
      }

      .level {
        position: static;
      }
    }

    .hours-mobile {
      display: block;
      inline-size: 100%;
      padding: 23px 0 0;
      margin-top: 20px;
      border-top: 1px solid $neutral40;

      summary {
        display: flex;
        gap: 2rem;
        align-items: center;
        justify-content: space-between;
        padding-inline-end: 0.5rem;
        margin-block-end: 1.25rem;
        font-size: 18px;
        font-weight: $semibold;
        cursor: pointer;
      }

      .toggle {
        @include iconPre($plus);
        display: flex;
      }

      &[open] {
        .toggle {
          @include iconPre($minus);
          font-size: 2px;
          cursor: pointer;
        }
      }
    }
  }
}

.logo-list {
  .heading {
    @include headingSecondary;
    padding-bottom: 0.643em;
  }

  ul {
    @include listUnstyled;
    display: inline-block;
  }

  li {
    float: left;
    padding-right: 55px;
    padding-bottom: 26px;
  }

  img {
    width: auto;
    height: 26px;
    filter: grayscale(1);
  }
}

// Includes scoping for the ContentTabs component
.benefits {
  $benefitsMaxWidth: 750px;
  $benefitsPadding: 20px;

  header {
    @include headingSecondary;
    position: relative;
    height: 1.571em;
  }

  .heading {
    @include headingSecondary;
    position: absolute;
    top: 0;
    width: 100%;
    padding-bottom: 0;
    text-align: center;
  }

  .benefits-container {
    max-width: $benefitsMaxWidth;
    margin: 0 auto;

    @include respond($benefitsMaxWidth + ($benefitsPadding * 2)) {
      margin-right: $benefitsPadding;
      margin-left: $benefitsPadding;
    }
  }

  .tab-nav {
    padding-top: 70px;

    @include respond($break60) {
      padding-top: 55px;
    }

    li {
      height: 54px;

      &.active {
        a {
          @include fontSC;
          bottom: -2px;
          font-size: 40px;
          font-weight: $light;

          @include respond($break60) {
            font-size: 28px;
            color: $neutralBlack;
          }
        }
      }
    }

    a {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      transition:
        bottom 0.2s $timing,
        font-size 0.2s $timing,
        font-weight 0.2s $timing;

      @include respond($break60) {
        color: $neutral90;
      }
    }
  }

  .panels {
    overflow: hidden;
    block-size: height 0.3s ease-in-out;

    .level {
      padding-right: 60px - $benefitsPadding;
      padding-left: 60px - $benefitsPadding;
    }

    .benefits-list {
      font-weight: $book;

      ul {
        list-style-type: disc;
      }

      li + li {
        padding-top: 16px;
      }
    }

    .deduction {
      display: block;
      padding-top: 35px;
      font-size: 15px;
      color: $neutral60;
    }

    .cta {
      padding-top: var(--sectionSpace70);
      text-align: center;
    }
  }
}

.link-group {
  header {
    + ul {
      li {
        padding-top: 65px;

        @include respond($break70) {
          padding-top: 52px;
        }
      }
    }
  }

  h2 {
    @include headingPrimary;
    padding-bottom: 0;

    @include respond($break70) {
      font-size: 32px;
    }
  }

  ul {
    @include listUnstyled;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 52px;

    @include respond($break70) {
      flex-direction: column;
      align-items: center;
      padding-bottom: 0;
    }
  }

  li {
    margin-right: 31px;
    margin-left: 31px;

    + li {
      @include respond($break70) {
        padding-top: 52px;
      }
    }
  }

  a {
    @include buttonSecondary;
    padding: 0 0 0.722em;
    margin-right: 0;
  }
}

.splash {
  @include splashImage;

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    background-color: $neutral20;
    transition: opacity 0.75s $timing;
  }

  &:hover {
    .button-primary {
      color: $neutralWhite;
      background-color: $neutral100;
    }

    .heading.white + .button-primary {
      color: $neutral100;
      background-color: $neutralWhite;
      border-color: $neutralWhite;
    }
  }

  + .splash {
    margin-top: 15px;
  }

  .splash-content {
    position: relative;
    z-index: 50;
    align-self: center;
    padding-right: var(--containerPadding);
    padding-left: var(--containerPadding);
    text-align: center;
  }

  .heading {
    @include headingPrimary;
    color: $neutral100;

    @include respond($break110) {
      font-size: 4.545vw;
    }

    @include respond($break65) {
      font-size: 32px;
    }

    &.black {
      color: $neutral100;
    }

    &.white {
      color: $neutralWhite;

      + .button-primary {
        color: $neutralWhite;
        border-color: $neutralWhite;
      }
    }
  }

  .pad-bottom {
    padding-bottom: 1em;
  }

  .button-primary {
    background-color: transparent;
  }

  // tall variant
  &.splash-tall {
    height: calc(100vh - #{$heroFullSpace} - #{$headerHeightTall});

    @include respond($break160 + 2 * $heroFullSpace) {
      margin: 0 $heroFullSpace;
    }

    @include respond($break100) {
      height: calc(100vh - 3.41vw - #{$headerHeightShort});
      margin: 0 3.41vw;
    }

    .splash-content {
      max-width: calc(#{$containerFocusWidth} + (2 * var(--containerPadding)));
      margin: auto;

      @include respond($break100) {
        max-width: 75%;
        margin-top: 20vw;
      }

      .heading {
        font-size: 72px;
        line-height: 1.333;

        @include respond($break110) {
          font-size: 6.545vw;
        }

        @include respond($break50) {
          font-size: 32px;
        }
      }

      .pad-bottom {
        padding-bottom: 0.714em;
      }

      .button-primary {
        color: $neutral100;
        background-color: $neutralWhite;
        border-color: $neutral100;
      }
    }

    &:hover {
      .splash-content {
        .button-primary {
          color: $neutralWhite;
          background-color: $neutral100;
          border-color: $neutral100;
        }
      }
    }
  }
}

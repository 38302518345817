// Base styles for ContentTabs component
// scoped in _visit-table.scss and _benefits.scss and here (for mini calendar)
.tab-nav {
  position: relative;
  padding-top: 60px;
  padding-bottom: 45px;
  text-align: center;

  ul {
    @include listUnstyled;
    display: flex;
    align-items: baseline;
    justify-content: center;
    border-bottom: 1px solid $neutral40;
  }

  li {
    position: relative;
    display: block;
    flex: 1;
    font-size: 16px;

    a {
      padding-bottom: 35px;
      line-height: 1;
      color: $neutralBlack;
    }
  }

  .calendar-tabs & {
    width: 181px;
    padding-top: 0;
    padding-bottom: 30px;
    margin: 0 auto;

    ul {
      padding-bottom: 10px;
    }

    li {
      font-size: 18px;
      font-weight: $book;

      &:not(.active) {
        a {
          opacity: 0.3;
        }
      }
    }
  }
}

.tab-marker {
  position: absolute;
  bottom: 45px;
  height: 4px;
  background-color: $neutral40;
  transition:
    width 0.1s $timing,
    left 0.2s $timing;

  .calendar-tabs & {
    bottom: 30px;
    height: 1px;
    background-color: $neutralBlack;
  }
}

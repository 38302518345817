$_breakpoint: $break80;

.callout-primary {
  @include fluid-scale(caption-padding-top, 60px, 47px);
  @include fluid-scale(caption-padding-inline, 120px, 40px);
  @include fluid-scale(caption-padding-bottom, 80px, 52px);

  --media-min-width: 60%;
  --pagination-right-desktop: 55px;
  --pagination-left-desktop: auto;
  --pagination-top-mobile: 0;
  --pagination-bottom-mobile: auto;
  --media-order: 0;
  --secondary-captions-order: 0;

  // Media callout with large left-side image, slideshow, or map
  display: flex;

  @include respond($_breakpoint) {
    flex-direction: column;
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      header h3 {
        text-decoration: underline;
      }
    }
  }

  &--with-secondary-captions {
    --secondary-captions-order: -1;
    --pagination-right-desktop: 0;
    --pagination-left-desktop: 55px;

    @include respond($_breakpoint) {
      --secondary-captions-order: 0;
    }
  }

  &--with-multiple-captions {
    @include fluid-scale(caption-padding-top, 60px, 25px);
    @include fluid-scale(caption-padding-inline, 60px, 40px);

    --media-min-width: 33.333%;
    --pagination-top-mobile: auto;
    --pagination-bottom-mobile: 0;
    --media-order: -1;
    --secondary-captions-order: -2;

    @include respond($_breakpoint) {
      --secondary-captions-order: -1;
    }

    .secondary-captions {
      @include respond($_breakpoint) {
        &::after {
          position: absolute;
          bottom: 0;
          left: 50%;
          display: block;
          width: 100px;
          height: 1px;
          content: '';
          background-color: currentColor;
          transform: translateX(-50%);
        }
      }
    }
  }

  .media {
    order: var(--media-order);
    min-width: var(--media-min-width);

    @include respond($_breakpoint) {
      height: 48.98vw;
      min-height: 320px;
    }
  }

  .image {
    display: flex;

    figure {
      width: 100%;
      background-position: 50% 50%;
      background-size: cover;
    }
  }

  .map {
    position: relative;

    .iframe-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -webkit-overflow-scrolling: touch;
    }

    iframe {
      width: 100%;
      height: 100%;
    }

    + .caption header h3 {
      @include respond($_breakpoint, min) {
        max-width: 4.25em;
      }
    }
  }

  .slider {
    position: relative;
    display: flex;
    overflow: hidden;

    .slides {
      @include listUnstyled;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      height: 100%;
      transition: transform 0.3s ease-in-out;
    }

    .slide,
    figure {
      display: flex;
    }

    figure {
      width: 100%;
      background-position: 50% 50%;
      background-size: cover;
    }
  }

  .captions,
  .secondary-captions {
    position: relative;
    width: 100%;
    overflow: hidden;

    .placeholders {
      display: flex;
      visibility: hidden;
      opacity: 0;
    }

    .fading .caption {
      position: absolute;
    }

    .pagination {
      position: absolute;
      right: var(--pagination-right-desktop);
      bottom: 0;
      left: var(--pagination-left-desktop);
      display: flex;
      align-items: center;
      padding: 19px 0 29px;

      @include respond($_breakpoint) {
        top: var(--pagination-top-mobile);
        right: auto;
        bottom: var(--pagination-bottom-mobile);
        left: 50%;
        padding-bottom: 0;
        background-color: transparent;
        transform: translateX(-50%);
      }
    }

    .ordinals {
      position: relative;
      width: 83px;
      height: 1em;
      font-size: 25px;
      font-weight: $book;

      @include respond($_breakpoint) {
        font-size: 16px;
      }
    }

    .wrapper {
      position: absolute;
      top: -6px;
      width: 100%;
      text-align: center;
    }

    .prev,
    .next {
      @include buttonUnstyled;
      top: 6px;
      font-size: 11px;
      color: $neutralBlack;

      @include respond($_breakpoint) {
        top: 0;
      }
    }

    .prev {
      @include iconPre($arrowLeftLong);
      position: relative;
      left: -17px;
    }

    .next {
      @include iconPre($arrowRightLong);
      position: relative;
      right: -17px;
    }
  }

  .secondary-captions {
    order: var(--secondary-captions-order);
  }

  .caption {
    @include basicReactFade('caption-fade');
    width: 100%;
    padding: var(--caption-padding-top) var(--caption-padding-inline) var(--caption-padding-bottom);
    color: $neutralBlack;

    @include respond($_breakpoint) {
      padding-right: 7.47vw;
      padding-left: 7.47vw;
      text-align: center;

      .content-section > .bg-white & {
        // padding-bottom: 0;
      }
    }

    &.link:hover {
      header h3 {
        text-decoration: underline;
      }
    }

    .label {
      @include labelPrimary;
      display: block;
      padding-bottom: 1.2em;
      font-size: 15px;
    }

    header h3 {
      min-height: 2.56em;
      margin-bottom: 1em;
      font-size: 50px;
      font-weight: $light;
      line-height: 1.28;

      @include respond($break110) {
        font-size: 45px;
      }

      @include respond($break100) {
        margin-bottom: 1.2em;
      }

      @include respond($_breakpoint) {
        min-height: unset;
        margin-top: 0.75em;
        font-size: 24px;
      }
    }

    .body {
      @include respond($_breakpoint) {
        font-size: 16px;
      }
    }

    .button-secondary {
      margin-top: 28px;
      margin-right: 0;
      cursor: pointer;
    }
  }
}

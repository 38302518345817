.content-section {
  padding-top: var(--sectionSpace100);
  padding-bottom: var(--sectionSpace100);

  // if first-child on a page with an interior nav
  .interior-nav + .page-content-wrapper &:first-child {
    padding-top: 60px;
  }

  .search-results-nav + & {
    padding-top: 0;
  }

  &.content-heading {
    padding-bottom: var(--sectionSpace70);

    + .content-body.bg-white {
      padding-top: var(--sectionSpace30);
    }
  }

  &.space-bottom {
    padding-top: 6px;
  }

  &.bg-gray {
    + .bg-gray {
      padding-top: 0;
    }

    + hr {
      margin-top: var(--sectionSpace100);
    }
  }

  &.bg-white,
  &.bg- {
    + .bg-white {
      padding-top: 0;
    }

    + .bg- {
      padding-top: 0;
    }
  }

  + hr {
    margin: 0 auto;
  }
}

hr {
  margin: 0 auto;

  + .container {
    &.bg-gray {
      margin-top: var(--sectionSpace100);
    }

    .callout-primary {
      margin-top: var(--sectionSpace100);
    }
  }

  .bg-gray + &,
  .bg-white:not(.content-section) + &,
  .content-adjacent + &,
  .splash + & {
    margin-top: var(--sectionSpace100);
  }

  + .splash,
  + .content-adjacent {
    margin-top: var(--sectionSpace100);
  }
}

.content-adjacent {
  + .content-adjacent,
  + .container {
    margin-top: 28px;
  }

  + .content-section.bg-gray {
    margin-top: var(--sectionSpace100);
  }
}

.content-body {
  // scoped for inside two-column CE
  .columns-2 & {
    width: 100%;
    margin: 0;
  }
}

.content-section-nested {
  + .content-section-nested {
    padding-top: var(--sectionSpace70);
  }
}

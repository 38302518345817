// Currently only used as part of Events Calendar
// This can be wrapped by .events-calendar for scoping
// Or, conversely abstracted to a shared component if necessary

.day-picker {
  padding-bottom: 0;

  .DayPicker-wrapper {
    &:focus {
      outline: none;
    }
  }

  .DayPicker-Body {
    display: flex;
    flex-direction: column;
  }

  .DayPicker-NavBar {
    position: relative;

    .DayPicker-NavButton {
      position: absolute;
      top: 7px;
      font-size: 10px;
      cursor: pointer;
      outline: none;
    }

    .DayPicker-NavButton--prev {
      @include iconPre($arrowLeftShort);
    }

    .DayPicker-NavButton--next {
      @include iconPre($arrowRightShort);
      right: 0;
    }
  }

  .DayPicker-Caption {
    padding-bottom: 0.778em;
    font-size: 18px;
    font-weight: $semibold;
    text-align: center;
    border-bottom: 1px solid $neutral40;
  }

  .DayPicker-WeekdaysRow {
    display: flex;
    justify-content: space-between;
  }

  .DayPicker-Weekday {
    width: 14.286%;
    padding: 20px 0 0.769em;
    font-size: 13px;
    font-weight: $semibold;
    text-align: center;
  }

  .DayPicker-Week {
    display: flex;
    justify-content: space-between;
  }

  .DayPicker-Day {
    position: relative;
    width: 14.286%;
    padding: 0.367em 0;
    font-size: 15px;
    font-weight: $book;
    text-align: center;
    cursor: pointer;
    outline: none;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1; // Local Z-Index
      display: block;
      width: 27px;
      height: 27px;
      margin-top: -13px;
      margin-left: -13px;
      content: '';
      border-radius: 100%;
      opacity: 0;
      transition:
        opacity 0.1s $timing,
        color 0.3s $timing,
        background-color 0.2s $timing;
    }

    &:hover::before {
      background-color: $neutral40;
      opacity: 1;
    }

    &--today {
      &::before {
        border: 1px solid $neutral60;
        opacity: 1;
      }
    }

    &--selected {
      color: $neutralWhite;

      &::before,
      &:hover::before {
        background-color: $neutral100;
        opacity: 1;
      }
    }

    &--outside {
      cursor: default;

      &::before {
        display: none;
        cursor: inherit;
      }
    }

    &--disabled {
      cursor: default;
      opacity: 0.3;

      &:hover::before {
        background-color: unset;
      }
    }

    &--closed {
      // color: $neutral60;
    }
  }
}

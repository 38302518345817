/*
  Page header styles shared by Exhibition, Event, Series, Collection, and Artwork Detail pages
*/

.detail-header {
  @include containerPrimary;
  align-items: baseline;
  padding-top: 45px;
  padding-bottom: 35px;

  @include respond($break110) {
    padding-top: 35px;
  }

  header {
    margin: 0 auto;
    text-align: center;

    @include respond($break110) {
      max-width: unset;
    }
  }

  .heading {
    @include headingPrimary;
    padding-bottom: 0;
    line-height: 1.28em;

    @include respond($break60) {
      font-size: 32px;
    }
  }

  time {
    display: block;
    padding-top: 35px;
  }

  .view-all {
    position: relative;

    &::after {
      position: absolute;
      top: 50%;
      width: 40px;
      height: 2px;
      margin-left: 40px;
      vertical-align: middle;
      content: '';
      background-color: $neutral40;
      transform: translateY(-50%);

      @include respond($break110) {
        margin-left: 80px;
      }
    }
  }
}

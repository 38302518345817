.m-form {
  display: flex;
  gap: clamp(40px, 5%, 100px);
  align-items: flex-start;
  wrap: nowrap;

  @include respond('1640px') {
    padding-right: 20px;
    padding-left: 20px;
  }

  @include respond($break110) {
    flex-direction: column;
  }

  &__card {
    &--hidden {
      display: none;
    }

    &--not-fieldset {
      width: 100%;
      max-width: 1100px;
      padding: 30px;
    }

    &--dark {
      --sectionSpace70: 40px;
      color: $neutralWhite;
      background-color: $neutralBlack;
    }

    &--announcement {
      block-size: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-block-start: 15px;

      @include respond($break110) {
        padding: 40px;

        &:first-child {
          align-items: flex-start;
        }
      }
    }
  }

  &__header {
    width: 20%;
    min-width: min-content;
    padding: 40px;
    border: 2px solid $neutral60;

    @include respond($break110) {
      width: 100%;
    }

    h1 {
      @include fluid-scale(font-size, 50px, 42px);
    }

    &--success {
      border: 2px solid $neutral60;
    }
  }

  &__right {
    flex-grow: 1;
    align-self: stretch;

    &--stack {
      display: flex;
      flex-direction: column;
      gap: 45px;
    }
  }

  &__fieldset {
    width: 100%;
    max-width: 1100px;
    padding-top: 0;
    margin-top: -5px;
    background-color: $neutral20;
    border-color: $neutral60;

    legend {
      font-size: 15px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.089em;
    }

    &--two-column {
      display: flex;
      gap: 30px;

      @include respond($break70) {
        flex-direction: column;
        gap: 0;
      }
    }
  }

  &__fieldgroup {
    width: 50%;
    max-width: 700px;
    padding: 0 30px;

    @include respond($break70) {
      width: 100%;
      max-width: 760px;
      padding: 0 10px 0 20px;
    }
  }

  &__field {
    padding-block-start: 20px;
    padding-block-end: 20px;
  }

  &__label {
    display: block;
    flex: 0 0 auto;
    padding-block-end: 5px;
    font-weight: 700;
  }

  &__field-descript {
    margin-block-end: 5px;
    font-weight: $book;
  }

  &__input {
    @include inputUnstyled;
    min-width: 300px;
    max-width: 400px;
    min-height: 30px;
    padding: 10px;
    background-color: $neutralWhite;
    border: 1px solid $neutral60;

    &::placeholder {
      color: $neutral60;
      opacity: 0.8;
    }
  }

  &__radio-wrapper,
  &__checkbox-wrapper {
    display: flex;
    gap: 10px;
    align-items: flex-start;

    & + & {
      margin-block-start: 5px;
    }

    input[type='text'] {
      margin-block-start: 8px;
    }

    label {
      cursor: pointer;
    }
  }

  &__radio,
  &__checkbox {
    margin-block-start: 0.25em;

    &--lg {
      margin-block-start: 0.35em;
    }
  }

  &__required {
    &::after {
      padding-left: 5px;
      color: $red;
      content: '*';
    }
  }

  &__error {
    margin-bottom: 10px;
  }

  &__readme {
    p + p {
      margin-top: 20px;
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    width: 100%;
    margin-block-start: 40px;
  }

  &__submit-button,
  &__nav-button {
    @include buttonPrimary;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    min-width: 168px;

    &:disabled {
      opacity: 0.5;

      &:hover,
      &:focus {
        color: $neutralBlack;
        background-color: $neutralWhite;
      }
    }
  }

  .icon-arrow-right {
    @include iconPre($arrowRightShort);
  }

  .icon-arrow-left {
    @include iconPost($arrowLeftShort);
  }

  &__comments-wrapper {
    margin-block-start: 40px;
  }

  &__comments-textarea {
    width: 100%;
    height: 200px;
    padding: 10px;
    margin-block-end: 40px;
    color: $neutralBlack;
    background-color: $neutralWhite;
    border: 1px solid $neutral60;
  }

  &__error {
    --sectionSpace30: 20px;
    padding: 30px;
    color: darken($red, 20%);
    background-color: lighten($colorError, 20%);
    border: 1px solid;

    & li {
      margin-left: 20px;
    }
  }

  input[type='radio']:disabled + label {
    cursor: default;
    opacity: 0.5;
  }
}

// Datepicker styles

.datePicker {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  & :focus-visible {
    border: 1px solid $neutral40;
  }

  &__wrapper {
    position: absolute;
    top: 50px;
    z-index: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 20px;
    background-color: white;
    box-shadow: 2px 2px 5px $neutral40;
  }

  &__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block-end: 0.8rem;
    border-bottom: 1px solid $neutral100;
  }

  &__caption {
    font-size: 1.125rem;
    font-weight: $semibold;
    text-align: center;
  }

  &__navButton {
    font-size: 0.625rem;
    cursor: pointer;
    background-color: inherit;
    border: none;
    outline: none;

    &--prev {
      @include iconPre($arrowLeftShort);
    }

    &--next {
      @include iconPre($arrowRightShort);
    }
  }

  &__wrapper {
    display: block !important;
  }

  &__table {
    & > thead {
      font-size: 0.82rem;
      font-weight: $semibold;
      text-align: center;
    }

    & td {
      margin-inline: 0.125rem;
      font-size: 0.94rem;
      font-weight: $book;
      text-align: center;
      cursor: pointer;
      outline: none;
      inline-size: 2.25rem;
      block-size: 2.25rem;
    }

    & td[aria-disabled='true'] {
      cursor: default;
      opacity: 0.3;
    }

    & td[aria-selected='true'] {
      color: $neutralWhite;
      background-color: $neutral100;
      border-radius: 50%;
      opacity: 1;
    }
  }

  &__tableDay {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem;
    inline-size: 2.25rem;
    block-size: 2.25rem;

    &[istoday='true'] {
      margin: auto;
      border: 1px solid $neutral60;
      border-radius: 50%;
      opacity: 1;
    }
  }
}

.grid-primary {
  @mixin gridLayout($padding, $width) {
    margin-bottom: -$padding;
    margin-left: -$padding;

    .grid-item {
      width: $width;
      padding-left: $padding;
    }
  }
  display: flex;
  flex-wrap: wrap;

  .grid-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 0;
    margin-bottom: var(--sectionSpace70);

    &.left {
      padding-right: 25px;
      padding-left: 0;

      @include respond($break60) {
        padding-right: 0;
      }
    }

    .content-section-nested {
      width: 100%;
    }
  }

  .columns-2 & {
    justify-content: center;
    margin-bottom: -40px;

    .grid-item {
      width: 50%;
      max-width: 455px;
      padding-left: 40px;

      @include respond($break60) {
        max-width: 100%;
      }

      &.left {
        padding-right: 40px;
        padding-left: 0;

        @include respond($break80) {
          padding-right: 4.76vw;
        }

        @include respond($break60) {
          padding-right: 0;
        }
      }

      &.right {
        @include respond($break80) {
          padding-left: 4.76vw;
        }

        @include respond($break60) {
          padding-left: 0;
        }
      }
    }
  }

  .columns-2.gutter-line & {
    margin-bottom: -25px;

    .grid-item {
      max-width: 100%;
      padding-left: 60px;

      @include respond($break80) {
        padding-left: 7vw;
      }

      @include respond($break60) {
        padding-left: 0;
      }

      &.left {
        position: relative;
        padding-right: 60px;
        padding-left: 0;

        @include respond($break80) {
          padding-right: 7vw;
        }

        @include respond($break60) {
          padding-right: 0;
        }

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 1px;
          content: '';
          background-color: $neutral40;

          @include respond($break60) {
            display: none;
          }
        }
      }
    }
  }

  .columns-3 & {
    @include gridLayout(35px, 33.333%);
  }

  .columns-4 & {
    @include gridLayout(75px, 25%);
  }

  @include respond($break120) {
    .columns-4 & {
      @include gridLayout(35px, 33.333%);
    }
  }

  @include respond($break100) {
    .columns-3 &,
    .columns-4 & {
      @include gridLayout(25px, 50%);
    }
  }

  @include respond($break60) {
    .columns-2 &,
    .columns-2.gutter-line &,
    .columns-3 &,
    .columns-4 & {
      @include gridLayout(0, 100%);
    }
  }
}

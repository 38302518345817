// Fonts
// -----

// Do your @font-face embedding here, in the privacy of your own partial

// NB: Once the site is in "production" we need to to host these
// on the server or a CDN

// @font-face {
//   font-family: 'Whitney';
//
//   font-style: normal;
//   font-weight: $light;
//   src: url('../fonts/Whitney-Light.eot');
//   src: url('../fonts/Whitney-Light.eot?#iefix') format('embedded-opentype'),
//     // url('../fonts/Whitney-Light.woff2') format('woff2'),
//     url('../fonts/Whitney-Light.woff') format('woff'),
//     url('../fonts/Whitney-Light.ttf') format('truetype');
// }
//
// @font-face {
//   font-family: 'Whitney';
//
//   font-style: normal;
//   font-weight: $book;
//   src: url('../fonts/Whitney-Book.eot');
//   src: url('../fonts/Whitney-Book.eot?#iefix') format('embedded-opentype'),
//     // url('../fonts/Whitney-Book.woff2') format('woff2'),
//     url('../fonts/Whitney-Book.woff') format('woff'),
//     url('../fonts/Whitney-Book.ttf') format('truetype');
// }
//
// @font-face {
//   font-family: 'Whitney';
//
//   font-style: normal;
//   font-weight: $medium;
//   src: url('../fonts/Whitney-Medium.eot');
//   src: url('../fonts/Whitney-Medium.eot?#iefix') format('embedded-opentype'),
//     // url('../fonts/Whitney-Medium.woff2') format('woff2'),
//     url('../fonts/Whitney-Medium.woff') format('woff'),
//     url('../fonts/Whitney-Medium.ttf') format('truetype');
// }
//
// @font-face {
//   font-family: 'Whitney';
//
//   font-style: normal;
//   font-weight: $semibold;
//   src: url('../fonts/Whitney-Semibold.eot');
//   src: url('../fonts/Whitney-Semibold.eot?#iefix') format('embedded-opentype'),
//     // url('../fonts/Whitney-Semibold.woff2') format('woff2'),
//     url('../fonts/Whitney-Semibold.woff') format('woff'),
//     url('../fonts/Whitney-Semibold.ttf') format('truetype');
// }
//
// @font-face {
//   font-family: 'Whitney';
//
//   font-style: normal;
//   font-weight: $bold;
//   src: url('../fonts/Whitney-Bold.eot');
//   src: url('../fonts/Whitney-Bold.eot?#iefix') format('embedded-opentype'),
//     // url('../fonts/Whitney-Bold.woff2') format('woff2'),
//     url('../fonts/Whitney-Bold.woff') format('woff'),
//     url('../fonts/Whitney-Bold.ttf') format('truetype');
// }
//
// @font-face {
//   font-family: 'WhitneySC';
//
//   font-style: normal;
//   font-weight: $light;
//   src: url('../fonts/Whitney-LightSC.eot');
//   src: url('../fonts/Whitney-LightSC.eot?#iefix') format('embedded-opentype'),
//     // url('../fonts/Whitney-LightSC.woff2') format('woff2'),
//     url('../fonts/Whitney-LightSC.woff') format('woff'),
//     url('../fonts/Whitney-LightSC.ttf') format('truetype');
// }
//
// @font-face {
//   font-family: 'WhitneySC';
//
//   font-style: normal;
//   font-weight: $book;
//   src: url('../fonts/Whitney-BookSC.eot');
//   src: url('../fonts/Whitney-BookSC.eot?#iefix') format('embedded-opentype'),
//     // url('../fonts/Whitney-BookSC.woff2') format('woff2'),
//     url('../fonts/Whitney-BookSC.woff') format('woff'),
//     url('../fonts/Whitney-BookSC.ttf') format('truetype');
// }
//
// @font-face {
//   font-family: 'WhitneySC';
//
//   font-style: normal;
//   font-weight: $medium;
//   src: url('../fonts/Whitney-MediumSC.eot');
//   src: url('../fonts/Whitney-MediumSC.eot?#iefix') format('embedded-opentype'),
//     // url('../fonts/Whitney-MediumSC.woff2') format('woff2'),
//     url('../fonts/Whitney-MediumSC.woff') format('woff'),
//     url('../fonts/Whitney-MediumSC.ttf') format('truetype');
// }
//
// @font-face {
//   font-family: 'WhitneySC';
//
//   font-style: normal;
//   font-weight: $semibold;
//   src: url('../fonts/Whitney-SemiboldSC.eot');
//   src: url('../fonts/Whitney-SemiboldSC.eot?#iefix') format('embedded-opentype'),
//     // url('../fonts/Whitney-SemiboldSC.woff2') format('woff2'),
//     url('../fonts/Whitney-SemiboldSC.woff') format('woff'),
//     url('../fonts/Whitney-SemiboldSC.ttf') format('truetype');
// }
//
// @font-face {
//   font-family: 'WhitneySC';
//
//   font-style: normal;
//   font-weight: $bold;
//   src: url('../fonts/Whitney-BoldSC.eot');
//   src: url('../fonts/Whitney-BoldSC.eot?#iefix') format('embedded-opentype'),
//     // url('../fonts/Whitney-BoldSC.woff2') format('woff2'),
//     url('../fonts/Whitney-BoldSC.woff') format('woff'),
//     url('../fonts/Whitney-BoldSC.ttf') format('truetype');
// }


// Mixins
// --------------------------
$fontCopy: 'Whitney A', 'Whitney B', sans-serif;
$fontSmallCaps: 'Whitney Nums A', 'Whitney Nums B', sans-serif;

.fr-dii {
  display: inline;
  margin: 10px 15px;
}

.fr-dib {
  display: block;
  margin: 10px auto;
}

.fr-fir {
  float: right;
  margin-right: 0;

  &.fr-dib {
    float: none;
  }
}

.fr-fil {
  float: left;
  margin-left: 0;

  &.fr-dib {
    float: none;
  }
}

.oc-img-rounded {
  border-radius: 50%;
}

.oc-img-bordered {
  border: 2px solid $neutral60;
}

.schedule {
  @include containerPrimary;
  display: flex;
  flex-direction: column;

  .series-nav {
    display: flex;
    align-items: center;
    padding-bottom: 42px;

    @include respond($break110) {
      order: -1;
    }

    .section-heading + & {
      margin-top: -18px;
    }

    .interior-nav {
      flex: 1;
      padding: 0;
    }
  }
}

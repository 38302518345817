.list-with-thumbs {
  $thumbWidth: 177px;
  $thumbPadding: 38px;
  $thumbTotalWidth: $thumbWidth + $thumbPadding;

  .list-heading {
    @include headingPrimary;
    text-align: center;
  }

  .list {
    padding-top: var(--sectionSpace70);

    .search-results & {
      padding-top: 0;
    }
  }

  a {
    display: block;

    &:hover {
      .title {
        text-decoration: underline;
      }
    }
  }

  .item {
    display: flex;
    justify-content: flex-end;
    padding-top: 40px;
    padding-bottom: 40px;
    color: $neutralBlack;
    border-bottom: 1px solid $neutral40;

    @include respond($break60) {
      flex-direction: column;
      text-align: center;
    }
  }

  .item-heading {
    width: 100%;

    > header { // if within .item-heading, like in search results
      width: 100%;
    }
  }

  .title {
    font-size: 28px;
    font-weight: $book;
    line-height: 1.429em;

    @include respond($break110) {
      font-size: 24px;
    }

    @include respond($break60) {
      font-size: 20px;
    }
  }

  .date {
    display: block;
    padding-top: 0.611em;
    font-size: 18px;
    font-weight: $book;

    @include respond($break110) {
      font-size: 16px;
    }
  }

  .source {
    @include buttonSecondary;
    padding-top: 40px;
    margin-right: 0;

    // CABS make all button-secondary font-size responsive?
    @include respond($break110) {
      font-size: 16px;
    }
  }

  .description {
    padding-top: 0.556em;
    font-size: 18px;
    font-weight: $book;
    line-height: 1.667;
  }

  .category {
    @include labelPrimary;
    height: 100%;
    padding-left: 2.5em;
    font-weight: $medium;
    line-height: 1.4;
    text-align: right;

    @include respond($break100) {
      order: -1;
      width: 100%;
      height: auto;
      padding-left: 0;
      margin-bottom: 10px;
      text-align: left;
    }

    @include respond($break60) {
      text-align: center;
    }
  }

  .thumb {
    order: -1;
    min-width: $thumbWidth;
    height: 127px;
    margin-right: $thumbPadding;
    background-position: 50% 50%;
    background-size: cover;

    @include respond($break60) {
      width: 100%;
      height: 50vw;
      margin-bottom: 24px;
    }

    &.no-image {
      position: relative;
      min-width: 127px;
      margin: 0 63px 0 25px;
      font-size: 15px;
      font-weight: $book;
      text-align: center;
      letter-spacing: 0.04em;
      border: 1px solid $neutral40;

      @include respond($break60) {
        display: none;
      }

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        content: 'Image not available';
        transform: translate(-50%, -50%);
      }
    }
  }

  // list item scoped for search results
  .item.search-result {
    .item-heading {
      display: flex;
      align-items: baseline;
      justify-content: space-between;

      @include respond($break100) {
        flex-direction: column;
      }
    }

    .date {
      padding-top: 0;
    }

    .thumb {
      margin-top: 5px;
    }
  }

  a:last-child > .item.search-result {
    border-bottom: none;
  }
}

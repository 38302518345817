/*
  Interior Nav menus have two components:
  1) Horizontal nav for larger screens
  2) Hidden dropdown nav for smaller screens
*/
.interior-nav {
  @include containerPrimary;
  padding-top: 65px;
  padding-bottom: 45px;
  text-align: center;

  &.search-results-nav {
    padding-top: 0;
  }

  ul {
    @include listUnstyled;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $neutral40;

    @include respond($break110) {
      display: block;
    }
  }

  li {
    padding: 0 22px;

    @include respond($break130) {
      padding-right: 11px;
      padding-left: 11px;
    }

    &.active {
      .link {
        border-color: $neutral100;
      }
    }
  }

  button {
    @include buttonUnstyled;
    @include iconPre($caretDown);
    position: relative;
    width: 100%;
    padding: 0;

    &::before {
      position: absolute;
      top: 4px;
      right: var(--containerPadding);
      font-size: 8px;
      line-height: 1.4;
      transition: transform $duration $timing;
      transform: rotate(0deg);
    }
  }

  .dropdown {
    display: none;

    .link {
      font-weight: $book;
    }

    @include respond($break110) {
      display: block;
    }
  }

  .horizontal-nav {
    @include respond($break110) {
      display: none;
    }
  }

  .wrapper {
    display: inline-block;

    @include respond($break110) {
      position: relative;
      display: block;
      margin: 0 calc(-1 * var(--containerPadding));
    }
  }

  .link {
    display: inline-block;
    height: 100%;
    padding: 0 1.689em 1.75em;
    font-size: 16px;
    color: $neutralBlack;
    border-bottom: 2px solid transparent;

    // search result quantity
    .qty {
      font-weight: $book;
    }
  }

  .activator {
    li {
      padding: 0;
    }
  }

  .link-list {
    position: absolute;
    top: 100%;
    width: 100%;
    padding-top: 57px;
    padding-bottom: 79px;
    visibility: hidden;
    background-color: $neutral20;
    border-bottom: none;
    box-shadow: 0 4px 7px rgba($neutralBlack, 0.13);
    opacity: 0;

    .link {
      font-size: 18px;
      border: none;
    }

    li.active .link,
    li:hover .link {
      font-size: 18px;
      font-weight: $semibold;
    }
  }

  .menu-open {
    button::before {
      transform: rotate(180deg);
    }

    .link-list {
      @include fadeIn($duration);
    }
  }

  .menu-closed {
    .link-list {
      @include fadeOut($duration);
    }
  }
}

.icon-list {
  ul {
    @include listUnstyled;
  }

  li {
    display: inline-block;
    font-size: 50px;
    color: hsl(0deg, 0%, 50%);

    + li {
      padding-left: 0.5em;
    }

    @include respond($break60) {
      font-size: 32px;
    }
  }

  .bg-white + .bg-white & {
    margin-top: -30px;
  }
}

.icon-wheelchair {
  @include iconPre($wheelchair);
}

.icon-asl {
  @include iconPre($asl);
}

.icon-sensory-friendly {
  @include iconPre($umbrella);
}

.icon-vision {
  @include iconPre($walkingMan);
}

.teaser {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  color: $neutralBlack;

  &:hover {
    figure {
      opacity: 0.6;
    }
  }

  figure {
    margin-bottom: 18px;
    transition: opacity $duration $timing;
  }

  .heading-secondary,
  .content-rte {
    max-width: 100%;
  }

  .columns-2 & .heading-secondary {
    // shrink sooner inside a two-col wrapper
    @include respond($break110) {
      font-size: 24px;
    }
  }

  .button-secondary {
    margin-top: 20px;
  }
}

.teaser-body {
  width: 100%;
}

.teaser-body img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.tab-content {
  .heading {
    @include basicReactFade;
  }

  .panels {
    position: relative;

    &:not(.overflow) {
      overflow: hidden;
    }

    .placeholder-list {
      display: flex;
      overflow: hidden;
      pointer-events: none;
      visibility: hidden;
      opacity: 0;

      .level {
        position: static;
      }
    }

    .placeholder {
      width: 100%;
      padding-right: 60px;
      padding-left: 60px;
    }

    .level {
      @include basicReactFade;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  // scoped for homepage calendar component
  &.horizontal {
    display: flex;
    justify-content: space-between;

    @include respond($break80) {
      flex-direction: column;
    }

    > .panels {
      width: 350px;

      .mini-calendar & {
        width: 100%;
        max-width: 417px;
      }

      @include respond($break80) {
        width: 100%;
        max-width: 400px;
        margin: 38px auto 0;
      }
    }

    .placeholder {
      padding-right: 0;
      padding-left: 0;
    }

    .placeholder-list.horizontal {
      .placeholder-list + div {
        // prevent miscalculation of height by nested placeholders
        // in mini-calendar tabs
        position: absolute;
        top: 0;
      }
    }
  }
}

.mini-calendar {
  min-height: 265px;
  transition: height $duration $timing;

  .name-link {
    margin-right: 0.625em;
    font-weight: $semibold;
    color: inherit;
    transition: color $duration $timing;

    &:hover {
      color: $neutral60;
    }
  }

  .name {
    font-size: 18px;
    line-height: 1.25;
  }

  .type {
    @include labelPrimary;
    display: block;
    font-size: 14px;
    line-height: 1.25;

    + .name {
      margin-top: 12px;
    }
  }

  .subtype {
    font-weight: $semibold;

    &::after {
      display: inline;
      padding-right: 2px;
      padding-left: 2px;
      font-weight: $book;
      content: '/';
    }
  }

  .add-button-wrapper {
    margin-top: 8px;
  }

  @include respond($break80) {
    min-height: 0;
    margin-bottom: 65px;
  }
}

/*
  Styles for the hero element Detail pages
*/

.detail-hero {
  .image {
    @include splashImage;
    display: flex;
    height: 676px;
    min-height: 300px;
    background-position: 50% 50%;
    background-size: cover;

    @include respond($break110) {
      height: 48.98vw;
    }

    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
      background-color: $neutral20;
      transition: opacity 0.75s $timing;
    }

    &.loaded::before {
      opacity: 0;
    }

    img[data-ref='image'] {
      // image src is pulled into background image `src`
      // with data-behavior="ResponsiveBackgroundImage"
      display: none;
    }
  }

  .cta-wrapper {
    @include containerPrimary;
    position: absolute;
    top: 556px;
    right: 0;
    left: 0;
    z-index: 50;
    text-align: right;

    @include respond($break110) {
      position: static;
      display: block;
      padding-top: 40px;
      text-align: center;
    }
  }

  + .content-section {
    @include respond($break110) {
      padding-top: 35px;
    }
  }

  &.exhibition-hero {
    .image {
      min-height: auto;
    }
  }
}

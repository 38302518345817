.exhibition-archive {
  padding-bottom: var(--sectionSpace100);

  .wrapper {
    @include containerPrimary;
    display: flex;
    align-items: baseline;

    @include respond($break90) {
      flex-direction: column;
      max-width: calc(#{$containerFocusWidth} + (2 * var(--containerPadding)));
    }
  }

  a {
    color: $neutralBlack;

    &:hover .title {
      text-decoration: underline;
    }
  }

  aside {
    order: -1;
    width: 250px;

    @include respond($break90) {
      width: 100%;
    }
  }

  .pagination-primary {
    @include respond($break90) {
      display: none;
    }
  }

  // overrides to base .prev-next pagination
  .prev-next {
    padding-top: 78px;
  }
}

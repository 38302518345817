/*
  Styles for events-style listings on Event, Series, & Exhibition Detail pages

  Wrapped by .event-detail & .exhibition-detail in /detail/_base.scss for scoping
*/

.chronological-list-secondary {
  .list-wrapper {
    display: flex;
    justify-content: space-between;

    @include respond($break130) {
      flex-direction: column;
    }
  }

  .list-aside {
    order: 1;
    padding-bottom: 44px;
    text-align: right;

    @include respond($break130) {
      order: 0;
      width: 100%;
      max-width: $containerFocusWidth;
      margin: 0 auto;
      text-align: initial;
    }
  }

  .list {
    @include listUnstyled;

    // width of parent minus width of gap on one side of child
    width: $containerBaseWidth - (($containerBaseWidth - $containerFocusWidth) * 0.5);

    @include respond($break130) {
      width: auto;
    }
  }

  .item {
    display: flex;
    justify-content: flex-end;

    @include respond($break130) {
      max-width: $containerFocusWidth;
      margin: 0 auto;
    }

    &:not(:last-child) {
      .heading {
        padding-bottom: 1.4em;
        margin-bottom: 1.5em;
        border-bottom: 1px solid $neutral40;

        @include respond($break70) {
          padding-bottom: 50px;
          margin-bottom: 40px;
        }
      }
    }
  }

  .heading {
    display: flex;
    flex-direction: column;
    order: 1;
    width: $containerFocusWidth;

    @include respond($break130) {
      flex: 1;
      width: auto;
    }
  }

  .title {
    order: 1;
    padding-top: 0.4em;
    font-size: 18px;
    font-weight: $book;
    line-height: 1.667;

    @include respond($break60) {
      font-size: 16px;
    }
  }

  .label {
    @include labelPrimary;
    font-weight: $medium;
    line-height: 1.4;
  }

  .time-for-small {
    display: none;

    @include respond($break70) {
      display: inline;
    }
  }

  .time-for-large {
    display: block;
    padding-top: 10px;
    padding-right: 48px;
    font-weight: $book;
    text-align: right;

    @include respond($break130) {
      width: 140px;
    }

    @include respond($break70) {
      display: none;
    }
  }

  .cta {
    padding-top: 52px;

    @include respond($break70) {
      text-align: center;
    }
  }

  .button-secondary {
    margin-right: 0;
  }
}

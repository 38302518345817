.content-heading {
  text-align: center;

  h1,
  h2,
  h3,
  h4 {
    padding-bottom: 0;

    + p {
      padding-top: var(--sectionSpace70);
    }
  }
}

.header-hamburger {
  $_toggleSize: 8px;
  $_togglePadding: 0.5em;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding-top: $headerHeightShort;
  visibility: hidden;
  background-color: $neutralWhite;
  opacity: 0;

  .menu-open & {
    @include fadeIn;
  }

  .menu-closed &,
  .search-open & {
    @include fadeOut;
  }

  .inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .site-navigation {
    padding-top: 7.612vh;
    text-align: center;

    @include respond($break60, max, height) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    ul {
      @include listUnstyled;
    }

    .level-1 {
      > li {
        + li {
          margin-top: 17px;

          @include respond($break60, max, height) {
            margin-top: 14px;
          }
        }

        &:last-child {
          margin-bottom: 34px;

          @include respond($break60, max, height) {
            margin-bottom: 28px;
          }
        }

        &.active a {
          font-weight: $semibold;
        }

        a {
          display: flex;
          justify-content: center;
          padding: 0 calc(var(--containerPadding) + #{$_toggleSize} + #{$_togglePadding});
          font-size: 24px;
          font-weight: $book;
          color: $neutralBlack;

          @include respond($break60, max, height) {
            font-size: 22px;
          }
        }
      }
    }

    .level-2 {
      &.closed {
        opacity: 0;
        transition: height $duration $timing;

        > li {
          display: none;
        }
      }

      &.open {
        opacity: 1;
        transition:
          height $duration $timing,
          opacity $duration $duration * 0.5 $timing;
      }

      li {
        margin-top: 0.5em;
      }

      li > a {
        font-size: 18px;
        font-weight: $book !important; /* stylelint-disable-line declaration-no-important */
        color: $neutral100;
      }
    }
  }

  .with-dropdown {
    position: relative;

    > button {
      @include buttonUnstyled;
      @include iconPost($caretDown);
      position: absolute;
      top: 50%;
      right: 0;
      padding: $_togglePadding var(--containerPadding) $_togglePadding $_togglePadding;
      transform: translateY(-50%);

      &::after {
        font-size: $_toggleSize;
        transition: transform $duration $timing;
      }

      &[aria-expanded='true'] {
        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }

  .footer {
    padding: 0 var(--containerPadding) 26px;
    text-align: center;

    @include respond($break60, max, height) {
      padding-bottom: 16px;
    }

    &::before {
      display: block;
      width: 100%;
      height: 1px;
      margin-bottom: 26px;
      content: '';
      background-color: $neutral40;

      @include respond($break60, max, height) {
        margin-bottom: 12px;
      }
    }
  }

  .location {
    display: block;
    padding-top: 17px;
    font-size: 14px;
    color: $neutral60;

    @include respond($break60, max, height) {
      padding-top: 10px;
    }

    span {
      display: block;
    }
  }
}

.slideshow {
  --slideshow-max-image-height: 50vh;

  @media all and (min-width: $break60) {
    --slideshow-max-image-height: 65vh;
  }

  @include overlay;
  @include basicReactFade;
  visibility: visible;
  opacity: 1;

  .close {
    @include overlayClose;
  }

  .mobile-close {
    @include overlayCloseMobile;

    @include respond($break60) {
      padding-top: 40px;
    }
  }

  .sliders {
    @include respond($break60) {
      display: flex;
      flex-flow: column;
    }
  }

  .header {
    padding: 41px 0 33px;
    text-align: center;

    @include respond($break60) {
      order: 1;
      padding: 10px 0 0;
    }

    &.title {
      .wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        @include respond($break60) {
          flex-direction: column;
        }

        .page-count {
          display: flex;
          gap: 0.5rem;
          align-items: center;

          @include respond($break100) {
            position: relative;
            display: flex;
          }
        }
      }
    }

    .wrapper {
      display: flex;
      justify-content: center;
      width: 70vw;
      margin: 0 auto;

      @include respond($break100) {
        display: block;
      }

      @include respond($break80) {
        width: 60vw;
      }
    }

    h3 {
      display: inline;
      padding: 0 11px;
      font-size: 24px;
      font-weight: $light;
      line-height: 1.2;
      text-align: left;

      @include respond($break100) {
        text-align: center;
      }

      @include respond($break60) {
        padding-top: 20px;
        font-size: 24px;
      }
    }
  }

  .page-count {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: $book;
    font-feature-settings: 'smcp';
    font-variant-caps: small-caps;

    // May need to size manually depending on
    // browser compatibility
    white-space: nowrap;

    &::after {
      display: inline-block;
      width: 50px;
      height: 2px;
      margin-left: 0.5rem;
      content: '';
      background: $neutral40;

      @include respond($break60) {
        display: none;
      }
    }

    .ordinal {
      font-family: $fontSmallCaps;
      font-feature-settings: normal;
    }
  }

  .flickity-viewport {
    width: 100vw;
    height: 100vh;
    transition: height 0.5s $timing;
  }

  .images {
    @include listUnstyled;
    width: 100vw;

    @include respond($break60) {
      order: 0;
    }

    .slide {
      display: flex;
      flex-direction: column;
      height: var(--slideshow-max-image-height);

      // @include respond($break50) {
      //   height: auto;
      // }

      img {
        width: 100%;
        max-width: 70vw;
        height: 100%;
        max-height: var(--slideshow-max-image-height);
        object-fit: contain;

        @include respond($break80) {
          max-width: 80vw;
        }
      }
      margin: 0 50px;

      @include respond($break100) {
        margin: 0 5.102vw;
      }

      @include respond($break60) {
        margin: 0 15px;
      }
    }

    .is-selected figure,
    .is-selected .figcaption {
      opacity: 1;
    }

    figure {
      opacity: 0.2;
      transition: opacity 0.15s $timing;

      img {
        width: auto;
        max-width: $containerBaseWidth;
        height: auto;
        max-height: 642px;

        @include respond($containerBaseWidth) {
          max-width: 73vw;
          height: auto;
          max-height: 50vh;
        }
      }
    }

    .figcaption {
      display: block;
      max-width: 73vw;
      padding-top: 0.357em;
      font-size: 14px;
      font-weight: $book;
      opacity: 0;
      transition: opacity $duration $timing 0.15s;
    }

    .flickity-button {
      display: none;
    }
  }

  .details {
    @include listUnstyled;
    width: 100vw;

    @include respond($break60) {
      order: 2;
    }

    .detail {
      width: 100%;
      opacity: 0.2;

      &.is-selected {
        opacity: 1;
      }

      .detail-header {
        @include respond($break60) {
          padding-top: 20px;
          padding-bottom: 0;

          .container-focus .heading {
            font-size: 24px;
            font-weight: $book;
          }

          .aside-right {
            order: 2;
            padding-top: 46px;
            padding-bottom: 0;

            /* stylelint-disable max-nesting-depth */
            a {
              margin-bottom: 0;
            }
            /* stylelint-enable */
          }
        }
      }
    }

    .flickity-button {
      display: none;
    }
  }

  @include respond($break110) {
    .meta-artist {
      order: 1;
      padding-bottom: var(--sectionSpace30);
    }

    .meta-artwork {
      order: 2;
    }
  }
}

.events-calendar {
  $utilityHeight: 82px;

  @include containerPrimary;
  position: relative;
  padding-bottom: var(--sectionSpace30);

  .day {
    margin-bottom: 36px;
  }

  .wrapper {
    display: flex;

    @include respond($break70) {
      display: block;
    }

    .results {
      width: 100%;
      max-width: calc(100% - 256px);
      padding-right: 157px;

      @include respond($break120) {
        padding-right: 7.3vw;
      }

      @include respond($break90) {
        max-width: calc(100% - 220px);
        padding-right: 6.5vw;
      }

      @include respond($break70) {
        max-width: 100%;
        padding-right: 0;
      }
    }

    .nav {
      order: 2;
      min-width: 256px;
      max-width: 256px;

      @include respond($break90) {
        min-width: 220px;
        max-width: 220px;
      }

      @include respond($break70) {
        width: 100%;
        max-width: none;
      }
    }
  }

  // Can appear inside or out of results
  .day-header {
    padding-bottom: 1.46em;
    padding-left: 117px;
    font-size: 50px;

    @include respond($break100) {
      padding-left: 0;
    }

    @include respond($break70) {
      font-size: 42px;
      text-align: center;
    }

    @include respond($break60) {
      padding-bottom: 1em;
      font-size: 32px;
    }

    h2 {
      font-size: 1em;
      font-weight: $light;

      .date {
        display: block;
        padding-top: 30px;
        font-size: 0.56em;
        font-weight: $book;

        @include respond($break60) {
          padding-top: 22px;
          font-size: 24px;
        }
      }

      .main-day-header {
        display: block;
        line-height: 1;
      }

      .weekday-multi {
        display: block;
        padding-bottom: 25px;
        font-size: 0.56em;
        font-weight: $book;

        @include respond($break60) {
          font-size: 24px;
        }
      }
    }
  }

  .filters {
    @include respond($break70) {
      position: relative;

      &.navHidden {
        height: 82px;
        transition: height $duration 0.25s $timing;
      }

      &.navVisible {
        // height set in JS
        transition: height 0.15s $timing;
      }
    }

    @include respond($break70, min) {
      height: auto !important; /* stylelint-disable-line */
    }
  }

  .utility {
    display: none;
    justify-content: space-between;
    padding: 29px 0 27px;
    border-top: 1px solid $neutral40;
    border-bottom: 1px solid $neutral40;

    @include respond($break70) {
      display: flex;
    }

    .select-date {
      @include buttonTertiary;
      @include iconPre($calender);
      padding-left: 38px;

      &::before {
        position: absolute;
        left: 0;
        font-size: 20px;
      }
    }
  }

  .day-picker,
  .filter-list {
    @include respond($break70) {
      position: absolute;
      top: $utilityHeight;
      left: 0;
      width: 100%;

      &.hidden {
        visibility: hidden;
        opacity: 0;
        transition: opacity $duration $timing, visibility $duration $timing;
      }

      &.visible {
        visibility: visible;
        opacity: 1;
        transition: opacity $duration 0.25s $timing,
          visibility $duration 0.25s $timing;
      }
    }
  }

  .day-picker {
    padding-top: 127px;
    padding-bottom: 66px;

    @include respond($break70) {
      padding-top: 14px;
      padding-bottom: 0;
    }
  }

  // scoped for calendar
  .filter-list {
    @include respond($break70) {
      padding-top: 13px;
      padding-bottom: 30px;
    }

    button.filter-list__clear {
      @include buttonQuaternary;
      margin-bottom: 1.267em;
      margin-left: 0;

      &:hover {
        font-weight: inherit;
      }
    }

    &__filter {
      position: relative;
      width: 100%;
      text-align: left;

      @include respond($break70) {
        color: inherit;
        text-align: center;
      }

      &.active {
        &::before {
          position: absolute;
          top: 4px;
          right: 0;
        }
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      @include respond($break70) {
        display: none;
      }

      h4 {
        padding-right: 1rem;
      }
    }
  }

  .results {
    @include respond($break70) {
      padding-top: 46px;
    }

    .days {
      @include listUnstyled;
    }

    // Similar to listing, but without time
    .note {
      position: relative;
      padding-bottom: calc(0.556em + 27px);
      padding-left: 117px;
      font-size: 18px;
      font-weight: $semibold;

      @include respond($break100) {
        padding-left: 0;
      }

      &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 117px;
        display: block;
        height: 1px;
        content: "";
        background-color: $neutral40;

        @include respond($break100) {
          left: 0;
        }
      }

      + .chronological-list-primary {
        margin-top: 26px;
      }
    }
  }
}

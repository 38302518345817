.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 29px;
  color: $neutralBlack;
  text-align: center;
  background-color: $neutralWhite;
  box-shadow: 0 1px 3px 0 transparentize($neutralBlack, 0.8);

  @media (hover) {
    &:hover {
      figure {
        opacity: 0.6;
      }

      .button-primary {
        color: $neutralWhite;
        background-color: $neutralBlack;
      }
    }
  }

  .main {
    width: 100%;
  }

  .body {
    padding-right: 18px;
    padding-left: 18px;
  }

  figure {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 66.857%;
    margin-bottom: 26px;
    background-color: $neutral40;
    background-position: 50% 50%;
    background-size: cover;
    -webkit-backface-visibility: hidden; // stylelint-disable-line
    transition: opacity $duration $timing;
  }

  header {
    margin-bottom: 19px;

    h4 {
      font-size: var(--card-header-font-size, 28px);
      font-weight: $book;
    }
  }

  .content-rte {
    font-size: 15px;
    color: $neutral80;

    p + p {
      margin-top: 4px;
    }
  }

  .button-primary {
    margin-top: 16px;
  }
}

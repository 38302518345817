// Donezo Generated Icon File:
// File will be overwritten if regenerated

// Icon Variables
// --------------------------------------------------------
$arrowLeftCircle: '\e900';
$arrowLeftLong: '\e901';
$arrowLeftShort: '\e902';
$arrowRightCircleLarge: '\e903';
$arrowRightCircle: '\e904';
$arrowRightLong: '\e905';
$arrowRightShort: '\e906';
$calender: '\e907';
$caretDown: '\e908';
$download: '\e909';
$hamburger: '\e90a';
$magnify: '\e90b';
$minus: '\e90c';
$play: '\e90d';
$plus: '\e90e';
$socialFacebook: '\e90f';
$socialInstagram: '\e910';
$socialTwitter: '\e911';
$xCircleCut: '\e912';
$xCircle: '\e913';
$x: '\e914';
$asl: '\e915';
$mail: '\e917';
$phone: '\e918';
$error: '\e919';
$success: '\e91a';
$umbrella: '\e91b';
$walkingMan: '\e91c';
$wheelchair: '\e91d';


@font-face {
  font-family: norton-icons;
  font-style: normal;
  font-weight: normal;
  src:
    url('../fonts/norton-icons.eot?#iefix') format('embedded-opentype'),
    url('../fonts/norton-icons.woff') format('woff'),
    url('../fonts/norton-icons.ttf') format('truetype'),
    url('../fonts/norton-icons.svg') format('svg');
}


@mixin fontIcon {
  font-family: norton-icons, sans-serif;
}

@mixin protoIcon {
  @include fontIcon;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  vertical-align: baseline;
  speak: none;
}

@mixin iconPre($icon) {
  speak: none;

  &::before {
    @include protoIcon;
    content: $icon;
  }
}

@mixin iconPost($icon) {
  speak: none;

  &::after {
    @include protoIcon;
    content: $icon;
  }
}

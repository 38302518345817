// Alternative to .tab-nav for homepage calendar view
.date-nav {
  position: relative;
  width: 300px;

  ul {
    @include listUnstyled;
  }

  li {
    display: block;

    + li {
      margin-top: 28px;
    }

    a {
      font-size: 40px;
      font-weight: $book;
      line-height: 1;
      color: $neutral40;
    }

    &.active {
      a {
        position: relative;
        color: $neutralBlack;

        @include iconPre($play);

        &::before {
          position: absolute;
          top: 50%;
          left: -23px;
          font-size: 10px;
          transform: translateY(-50%);
        }
      }
    }
  }

  .cta {
    padding-top: 45px;

    a {
      @include iconPost($arrowRightShort);
      font-size: 16px;
      font-weight: $medium;
      color: $neutralBlack;
      letter-spacing: 0.063em;

      &::after {
        margin-left: 14px;
        font-size: 10px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.desktop {
    @include respond($break80) {
      display: none;
    }
  }

  &.mobile {
    display: none;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;

    @include respond($break80) {
      display: block;
    }

    li {
      &.active a::before {
        opacity: 0;
      }
    }

    a {
      position: relative;
      display: block;
      padding-bottom: 27px;
      font-size: 27px;
      font-weight: $light;
      color: $neutralBlack;

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }

    button {
      @include buttonUnstyled;
      @include iconPre($caretDown);
      position: absolute;
      top: 1px;
      display: none;

      &::before {
        font-size: 8px;
        line-height: 1;
      }

      @include respond($break80) {
        display: block;
      }

      &.inactive {
        opacity: 0.3;
      }

      &.prev {
        left: 0;

        &::before {
          transform: rotate(90deg);
        }
      }

      &.next {
        right: 0;

        &::before {
          transform: rotate(-90deg);
        }
      }
    }
  }
}

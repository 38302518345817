$_flickity-dots-position: -43px;
$_flickity-dots-breakpoint: $break60;

.card-slider {
  @include fluid-scale(card-header-font-size, 28px, 22px);
  padding-left: calc((#{$containerMaxWidth} - #{$containerBaseWidth}) / 2);

  @include respond($break160) {
    padding-left: calc((100vw - #{$containerBaseWidth}) / 2);
  }

  @include respond($break130) {
    padding-left: var(--containerPadding);
  }

  .slides {
    @include listUnstyled;
  }

  // Add adaptive height to flickity cells
  .flickity-resize {
    .slider-header,
    .slider-item {
      min-height: 100%;
    }
  }

  .flickity-page-dots {
    bottom: $_flickity-dots-position;
    left: calc(-1 * (var(--containerPadding) / 2));
    display: none;

    @include respond($_flickity-dots-breakpoint) {
      display: block;
    }

    .dot {
      width: 7px;
      height: 7px;
      vertical-align: middle;
      background-color: $neutral60;
      border-radius: 100%;
      transition:
        width 0.2s $timing,
        height 0.2s $timing;
    }

    .is-selected {
      width: 10px;
      height: 10px;
      background-color: $neutralBlack;
    }
  }

  .slider-item {
    @include fluid-scale(margin-left, 37px, 20px);
    display: flex;
    min-width: 350px;
    max-width: 350px;

    // Slider items need a tiny bit of bottom padding to
    // acount for card shadow that's cut off by slider overflow
    padding-bottom: 4px;

    @include respond($break80) {
      min-width: 320px;
      max-width: 320px;
    }

    // Special breakpoint for mobile sizing
    @include respond(412px) {
      min-width: 70vw;
      max-width: 70vw;
    }

    & + .slider-item {
      margin-left: var(--margin-left);
    }
  }

  .card {
    width: 100%;
    margin: 1px;
  }

  // Style items from library
  .flickity-button {
    @include buttonUnstyled;
    @include fluid-scale(button-size, 61px, 50px);
    @include fluid-scale(line-height, 56px, 48px);
    position: absolute;
    top: 36%;
    display: flex;
    width: var(--button-size);
    height: var(--button-size);
    padding: 0;
    background-color: transparentize($neutralWhite, 0.1);
    border: 1px solid $neutralBlack;
    transition: opacity 0.3s $timing;

    @include respond($break60) {
      // display: none;
    }

    &:disabled {
      opacity: 0;
    }

    &.previous {
      @include iconPre($arrowLeftLong);
      left: 0;
      transform: translate(-50%, -50%);

      &::before {
        line-height: var(--line-height);
      }
    }

    &.next {
      @include iconPost($arrowRightLong);

      &::after {
        line-height: var(--line-height);
      }
    }

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      margin: auto;
      font-size: 13px;
      transform: translate(-50%, -50%);
    }

    svg {
      display: none;
    }
  }
}

.slider-header {
  @include fluid-scale(padding-right, 55px, 30px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  &--desktop {
    min-width: 350px;
    max-width: 350px;
    padding-right: var(--padding-right);

    @include respond($break80) {
      display: none;
    }
  }

  &--mobile {
    display: none;
    padding-bottom: var(--sectionSpace30);
    padding-left: var(--containerPadding);
    text-align: center;

    @include respond($break80) {
      display: block;
    }
  }

  .heading-secondary {
    font-size: 50px;
    font-weight: $light;
    line-height: 1.28;

    @include respond($break110) {
      font-size: 42px;
    }

    @include respond($break60) {
      font-size: 35px;
    }

    &:only-child {
      padding-bottom: 0;
    }
  }
}

.slider-footer {
  padding-left: var(--containerPadding);
  margin-top: var(--containerPadding);
  text-align: center;

  @include respond($_flickity-dots-breakpoint) {
    margin-top: calc(#{$_flickity-dots-position * -1} + var(--containerPadding));
  }

  &--mobile {
    display: none;

    @include respond($break80) {
      display: block;
    }
  }
}

$_height: 300px;

.gallery-slider {
  &__slide-wrapper {
    height: $_height;
  }

  &__slide {
    position: relative;
    height: 100%;

    & + & {
      margin-left: 35px;
    }
  }

  &__image {
    width: auto;
    height: 100%;
  }

  &__caption-wrapper {
    @include containerPrimary;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    margin-top: 2em;
  }

  &__caption {
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    font-size: 14px;
    font-weight: $book;
    line-height: 1.4;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s;

    a {
      color: inherit;
      text-decoration: underline;
    }

    &[data-active='true'] {
      visibility: visible;
      opacity: 1;
    }
  }

  .flickity-button {
    top: $_height * 0.5;
  }
}

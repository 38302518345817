/*
  Styles for Featured Program on Exhibition Detail page
*/

.program-featured {
  .heading {
    display: flex;

    @include respond($break130) {
      flex-direction: column;
      width: 100%;
      max-width: $containerFocusWidth;
      margin: 0 auto;
    }
  }

  .labels {
    display: flex;
    justify-content: space-between;

    // width of parent minus width of gap on one side of child
    width: $containerBaseWidth - (($containerBaseWidth - $containerFocusWidth) * 0.5);

    @include respond($break130) {
      flex-wrap: wrap;
      justify-content: flex-start;
      width: auto;
    }
  }

  h2 {
    @include respond($break130) {
      &::after {
        white-space: pre;
        content: ':\0020';
      }
    }
  }

  .category {
    order: -1;

    @include respond($break130) {
      order: 0;
    }
  }

  .date-wrapper {
    position: relative;
    flex: 1;
    order: -2;

    @include respond($break130) {
      order: 0;
    }
  }

  .date {
    position: absolute;
    right: 60px;
    display: block;
    padding-top: 44px;
    font-size: 50px;
    font-weight: $light;
    line-height: 1;

    @include respond($break130) {
      position: static;
    }

    @include respond($break60) {
      font-size: 48px;
    }
  }

  .title {
    @include headingSecondary;
    padding-top: 20px;

    @include respond($break60) {
      font-size: 24px;
    }
  }

  .desc {
    font-size: 18px;
    font-weight: $book;
    line-height: 1.667;

    @include respond($break60) {
      font-size: 16px;
    }
  }

  .cta {
    padding-top: 40px;
  }

  .price {
    padding-left: 30px;
    font-size: 15px;
    font-weight: $book;
    color: $neutral60;
  }
}

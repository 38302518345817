.slider-nav {
  max-width: 700px;
  margin: 0 auto;

  .wrapper,
  ul,
  .flickity-viewport {
    width: 100%;
  }

  li {
    white-space: nowrap;

    .link {
      transition: border-color $duration $timing;
    }

    &.is-selected:not(.active) {
      .link {
        border-color: $neutral40;
      }
    }
  }

  // Style items from library
  .flickity-button {
    @include buttonUnstyled;
    position: absolute;
    top: 36%;
    width: 51px;
    height: 51px;
    transition: opacity 0.3s $timing;

    &:disabled {
      opacity: 0;
    }

    &.previous {
      @include iconPre($arrowLeftCircle);
      left: -125px;
    }

    &.next {
      @include iconPre($arrowRightCircle);
      right: -125px;
    }

    &::before {
      top: 0;
      right: 0;
      left: 0;
      font-size: 51px;
      transform: rotate(0);
    }

    svg {
      display: none;
    }
  }
}

.artwork-detail {
  h3 {
    padding-bottom: 0;
  }

  .column-wrapper {
    columns: 2 auto;
    column-fill: balance;
    column-gap: var(--sectionSpace70);

    @include respond($break50) {
      column-count: 1;
    }
  }

  .meta-item {
    break-inside: avoid;
    page-break-inside: avoid;

    p:last-child {
      margin-bottom: var(--sectionSpace30);
    }
  }

  .related {
    @include listUnstyled;

    li {
      margin-top: 18px;
    }

    a {
      @include buttonSecondary;
    }
  }
}

// Variables
//----------
// Global variables go here. In 2018, these may be a mix of
// scss variables and css custom-properties (--*)
// https://developer.mozilla.org/en-US/docs/Web/CSS/--*

// Measurements that don't change should generally use scss
// variables that can change via a media query or a user interaction
// should use --*s.

// :root level --*s (globals) should only live here.
// Variables that aren't global (typography, component measurements, etc.)
// should live inside a .class, ideally in another partial.

// Colors
$green: #9fe19c;
$yellow: #f6f18b ;
$red: #ef867f;

// Neutrals
$neutralWhite: #fff;
$neutralBlack: #000;
$neutral100: #191819;
$neutral90: #3f3f3f;
$neutral80: #5b5a5e;
$neutral60: #999;
$neutral40: #dbdbdb;
$neutral20: #f3f4f5;

// State Indicators
$colorSuccess: #aae995;
$colorError: #e99595;

// Responsive breakpoints
// --------------------------------------------------------
$break160: 1600px;
$break130: 1367px;
$break120: 1230px;
$break110: 1100px;
$break100: 980px;
$break90: 930px;
$break80: 840px;
$break70: 760px;
$break65: 690px;
$break60: 660px;
$break50: 470px;

// $break40: ;
$break30: 320px;

// $break20: ;
// $break10: ;


// Measurements
// --------------------------------------------------------
$baseLineHeight: 1.4;
$verticalSpace: $baseLineHeight * 1em;
$containerMaxWidth: $break160;
$containerBaseWidth: 1127px;
$containerWideWidth: 880px;
$containerFocusWidth: 620px;
$headerHeightTall: 112px;
$headerHeightShort: 62px;
$heroFullSpace: 72px;
$heroScrollHintSize: 88px;
$buttonIconPadding: 22px;

:root {
  --containerPadding: 120px;
  --sectionSpace100: 110px;
  --sectionSpace70: 70px;
  --sectionSpace30: 30px;

  @include respond($break130) {
    --containerPadding: 8.78vw;
  }

  @include respond($break110) {
    --containerPadding: 7.47vw;
  }

  @include respond($break60) {
    --sectionSpace70: 50px;
    --sectionSpace100: 70px;
  }
}

// Typography
// --------------------------------------------------------
$light: 300;
$book: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

// Animation
// --------------------------------------------------------
$duration: 0.2s;
$timing: ease-in-out;

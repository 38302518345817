@mixin proto-add-button {
  @include buttonSecondary;
  z-index: 0;
  padding: 0;
  font-family: inherit;
  line-height: $baseLineHeight;
  color: inherit !important;
  background: transparent;
  border: none;
  border-radius: 0;
  outline-color: inherit;
  box-shadow: none !important;

  .addeventatc_icon {
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    background-size: 1em 1em;
  }

  .addeventatc_dropdown {
    padding-bottom: 6px;

    .copyx {
      display: none;
    }
  }
}

.page-content .addeventatc:not(.full) {
  @include proto-add-button;
  top: -6px;
  width: 1em;
  height: 1em;

  @include respond($break60) {
    font-size: 18px;
  }

  .addeventatc__button-text {
    position: absolute;
    top: -2px;
    right: 100%;
    width: max-content;
    padding-right: 10px;
    font-size: 16px;
    font-weight: $medium;
    letter-spacing: 0.063em;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-out, transform 0.2s 0.05s ease-in, visibility 0s 0.2s;
    transform: translateX(50%);

    @include respond($break120) {
      padding-top: 2px;
      font-size: 15px;
    }
  }

  &:hover,
  &[aria-expanded='true'] {
    font-size: 18px;
    background-color: transparent;

    .addeventatc__button-text {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.2s 0.1s ease-out, transform 0.2s ease-out;
      transform: translateX(0);
    }
  }
}

.page-content .addeventatc.full {
  @include proto-add-button;
  @include labelPrimary;
  margin-top: calc(var(--sectionSpace30) / 2);
  font-weight: $medium;
  line-height: 1.4;
  text-align: right;
  white-space: nowrap;

  @include respond($break110) {
    margin-top: 0;
  }

  .addeventatc_icon {
    display: none;
  }

  &:hover {
    text-decoration: underline;
  }

  .addeventatc_dropdown {
    text-transform: none;
    letter-spacing: 0.029em;
  }
}

.staff-list {
  $itemWidth: 360px;
  $itemPadding: 30px;
  $flexBreak: 932px;

  header {
    text-align: center;
  }

  .dept {
    + .dept {
      padding-top: 60px;

      @include respond($flexBreak) {
        padding-top: 30px;
      }
    }
  }

  .dept-heading {
    @include labelPrimary;
    padding-bottom: 100px;
    font-weight: $medium;

    @include respond($flexBreak) {
      padding-bottom: 40px;
    }
  }

  .dept-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .staff {
    flex: 0 0 auto;
    width: $itemWidth;
    padding-bottom: 40px;

    @include respond($flexBreak - 1) {
      width: 100%;
      text-align: center;
    }

    &:nth-child(odd) {
      @include respond($flexBreak, min) {
        margin-right: $itemPadding;
      }
    }
  }

  .name {
    font-size: 28px;
    font-weight: $book;

    @include respond($break60) {
      font-size: 24px;
    }
  }

  .title {
    display: block;
    padding-top: 0.667em;
    font-size: 18px;
    font-weight: $book;

    @include respond($break60) {
      font-size: 16px;
    }
  }

  .contact {
    padding-top: 0.941em;
    font-size: 17px;
    font-weight: $book;

    @include respond($break60) {
      font-size: 15px;
    }

    .phone {
      @include iconPre($phone);

      &::before {
        margin-top: -2px;
        margin-right: 5px;
        font-size: 1.353em;
        vertical-align: middle;
      }

      + .email {
        margin-left: 28px;
      }
    }

    .email {
      @include iconPre($mail);
      color: inherit;

      @include respond(413px) {
        display: block;
        padding-top: 0.5em;
      }

      &::before {
        margin-top: -2px;
        margin-right: 5px;
        font-size: 0.824em;
        vertical-align: middle;
      }

      &:hover {
        text-decoration: underline;
      }

      .hostname::before {
        content: '@';
      }
    }
  }
}

.pagination-primary,
.pagination-secondary {
  padding-top: var(--sectionSpace100);
  text-align: center;

  a {
    @include buttonSecondary;
    margin-right: 0;

    &:nth-child(2)::before {
      position: absolute;
      top: 50%;
      display: inline-block;
      height: 75px;
      margin-top: -36px;
      content: '';
      border-left: 1px solid $neutral40;
    }
  }
}

.pagination-primary {
  // variable value taken from @mixin buttonSecondary
  $linkPadding: 61px;


  @include respond($break90) {
    padding-top: 50px;
  }

  a {
    &:first-child:not(:only-child) {
      margin-right: $linkPadding;

      @include respond($break50) {
        margin-right: 12.98vw;
      }
    }

    &:nth-child(2) {
      margin-left: $linkPadding;

      @include respond($break50) {
        margin-left: 12.98vw;
      }

      &::before {
        left: -$linkPadding;
        width: $linkPadding;

        @include respond($break50) {
          left: -12.98vw;
          width: 12.98vw;
        }
      }
    }
  }

  .prev {
    @include iconPre($arrowLeftShort);

    &::before {
      padding-right: 1.25em;
      font-size: 12px;
    }
  }

  .next {
    @include iconPost($arrowRightShort);
    margin-right: 0;

    &::after {
      padding-left: 1.25em;
      font-size: 12px;
    }
  }
}

.pagination-secondary {
  // variable value taken from @mixin buttonSecondary
  $linkPadding: 61px;
  display: flex;
  justify-content: space-between;
  margin-left: calc(77px + 40px);

  @include respond($break100) {
    margin-left: 0;
  }

  @include respond($break70) {
    padding-top: 0;
  }

  a {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-around;
    padding: 0;
    font-size: 17px;
    border: 0;

    .icon {
      font-size: 12px;
    }

    span {
      min-width: 60%;
    }

    &:only-child {
      justify-content: center;
      width: 100%;

      span {
        min-width: 0;
      }
    }

    &:first-child:not(:only-child) {
      padding-right: $linkPadding;

      @include respond($break130) {
        padding-right: 4.4vw;
      }
    }

    &:nth-child(2) {
      padding-left: $linkPadding;

      @include respond($break130) {
        padding-left: 4.4vw;
      }

      &::before {
        left: 0;
      }
    }

    &.prev .icon {
      @include iconPre($arrowLeftShort);

      &::before {
        padding-right: 50px;

        @include respond($break130) {
          padding-right: 4.32vw;
        }
      }
    }

    &.next .icon {
      @include iconPost($arrowRightShort);

      &::after {
        padding-left: 50px;

        @include respond($break130) {
          padding-left: 4.32vw;
        }
      }
    }
  }
}

.video-teaser,
.video-teaser-grid {
  .heading-secondary {
    padding-bottom: var(--sectionSpace70);

    @include respond($break60) {
      font-size: 24px;
    }
  }

  .teaser {
    display: block;
    cursor: pointer;

    &:hover,
    &:focus {
      outline: 0;

      .title {
        text-decoration: underline;
      }
    }

    figure {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 66.857%;
      margin-bottom: 12px;
      background-position: 50% 50%;
      background-size: cover;
      transition: opacity $duration $timing;
      -webkit-backface-visibility: hidden; // stylelint-disable-line
      @include iconPost($play);

      &::before,
      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &::before {
        width: 70px;
        height: 70px;
        content: '';
        background-color: rgba($neutralBlack, 0.72);
        border-radius: 50%;
      }

      &::after {
        padding-left: 2px;
        font-size: 15px;
        color: $neutralWhite;
      }
    }
  }

  .title {
    @include copyPrimary;
    font-weight: $semibold;
    line-height: 1.444;

    + .content-rte {
      margin-top: 10px;
    }
  }

  .cta {
    display: block;
    padding-top: 38px;
    text-align: center;
  }

  .button-secondary {
    margin-right: 0;
  }
}

.video-teaser-grid {
  &__title {
    @include copyPrimary;
    margin-bottom: 10px;
    font-weight: $semibold;
    line-height: 1.444;
  }

  &__no-content-message {
    font-size: 1.9em;
    font-weight: 400;
    text-align: center;
  }
}

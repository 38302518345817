// CABS overlay-primary if need be
.overlay {
  @include overlay;

  .close {
    @include overlayClose;
  }

  .mobile-close {
    @include overlayCloseMobile;
  }
}

/* stylelint-disable scss/media-feature-value-dollar-variable  */
.hero {
  @include splashImage;

  &:not(.hero-full, .hero-slider__slide)::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    background-color: $neutral20;
    transition: opacity 0.75s $timing;
  }

  .hero-content {
    position: relative;

    //z-index: 50;
    width: 100%;
    padding-right: var(--containerPadding);
    padding-left: var(--containerPadding);
    text-align: center;

    @include respond($break100) {
      margin-top: 16vw;
    }

    // shrink font sizes sooner if there's a CTA
    header:not(:only-child) {
      .heading {
        @media all and (max-height: 850px) and (min-aspect-ratio: 3/2) { /* stylelint-disable-line scss/operator-no-unspaced */
          font-size: 8.5vh;
        }
      }
    }

    // CABS custom property
    .heading {
      font-size: 72px;
      font-weight: $light;
      line-height: 1.333;
      color: $neutralBlack;

      @include respond($break110) {
        font-size: 6.545vw;
      }

      @media all and (max-height: 750px) and (min-aspect-ratio: 3/2) { /* stylelint-disable-line scss/operator-no-unspaced */
        font-size: 9.6vh;
      }

      @include respond($break50) {
        font-size: 32px;
      }

      &:not(:only-child) {
        margin-bottom: 0.714em;
      }

      &.white {
        color: $neutralWhite;
      }
    }
  }

  .cta {
    @include buttonPrimary;
  }

  // homepage variant
  &.hero-full {
    height: calc(100vh - #{$heroFullSpace} - #{$headerHeightTall});

    &--slider {
      width: 100%;
    }

    @media all and (max-height: 750px) and (min-aspect-ratio: 1/1) { /* stylelint-disable-line scss/operator-no-unspaced */
      align-items: flex-start;
    }

    @media all and (min-width: $break90) and (max-width: $break100) {
      height: calc(100vh - 3.41vw - #{$headerHeightTall});
    }

    @include respond($break90) {
      height: calc(100vh - 3.41vw - #{$headerHeightShort});
    }

    @media all and (max-width: $break100) and (max-aspect-ratio: 1/1) { /* stylelint-disable-line scss/operator-no-unspaced */
      max-height: 60vh;
    }

    .hero-content {
      @include respond($break100) {
        margin-top: 20vw;
      }

      @media all and (max-height: 750px) and (min-aspect-ratio: 1/1) { /* stylelint-disable-line scss/operator-no-unspaced */
        margin-top: 15vh;
      }
    }
  }
}

.hero-full-wrapper {
  @include container;
  position: relative;
  width: 100%;

  @include respond($break160 + 2 * $heroFullSpace) {
    width: calc(100% - #{$heroFullSpace * 2});
  }

  @include respond($break100) {
    width: calc(100% - 6.82vw);
  }

  &.loaded {
    .background::before {
      opacity: 0;
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-position: 50% 50%;
    background-size: cover;

    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
      background-color: $neutral20;
      transition: opacity 0.75s $timing;
    }

    img[data-ref='image'] {
      display: none;
    }

    .scroll-capture {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  .hero {
    .hero-content {
      max-width: calc(#{$containerWideWidth} + (2 * var(--containerPadding)));
    }

    header {
      position: relative;
      pointer-events: none;
    }

    div[data-ref='logo'] {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      opacity: 0;
      transform: translateY(-50%);
    }

    .scroll-hint {
      @include iconPost($caretDown);
      position: absolute;
      bottom: $heroFullSpace + $heroScrollHintSize * 0.5;
      left: 50%;
      color: $neutralBlack;
      cursor: pointer;
      transform: translateX(-50%);
      animation: 0.5s ease-in infinite alternate bounce;

      /* stylelint-disable scss/operator-no-unspaced */
      @media all and (max-height: 850px) and (min-aspect-ratio: 1/1),
        all and (max-width: $break100) and (max-aspect-ratio: 1/1) {
        bottom: 0;
      }
      /* stylelint-enable scss/operator-no-unspaced */

      @media all and (max-height: 850px) and (min-aspect-ratio: 1/1) and (min-width: $break50) and (max-width: $break100) { /* stylelint-disable-line scss/operator-no-unspaced */
        bottom: 15vh;
      }

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: $heroScrollHintSize;
        height: $heroScrollHintSize;
        content: '';
        border: 1px solid;
        border-radius: 50%;
        transform: translate(-50%, -50%);

        @media all and (max-width: $break100), (max-height: 500px) {
          width: 66px;
          height: 66px;
        }
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 9.68px;
        transform: translate(-50%, -50%);
      }

      @keyframes bounce {
        from {
          transform: translateY(0);
        }

        to {
          transform: translateY(10px);
        }
      }
    }
  }
}

// if .hero is an <a> element
a.hero {
  color: inherit;

  &:hover {
    .cta {
      color: $neutralWhite;
      background-color: $neutral100;
    }
  }
}

.hero-caption {
  // if on a full page slider
  .hero-full--slider & {
    // set the caption to the bottom
    position: absolute;
    bottom: 0;
    width: 100%;

    // make sure there is a height even when there is no text
    min-height: 28px;

    // give it a white background to match the site
    background: white;
  }

  p {
    padding-top: 0.65em;
    padding-right: var(--sectionSpace30);
    padding-left: var(--sectionSpace30);
    font-size: 14px;
    font-weight: $book;
    line-height: 1.4;

    @include respond($break100) {
      padding-right: 3.06vw;
      padding-left: 3.06vw;
    }

    @include respond($break50) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}

/* styleline-enable scss/media-feature-value-dollar-variable */

.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 110px;

  @include respond($break60) {
    flex-direction: column;
    justify-content: center;
    padding-bottom: 70px;
  }

  &__dropdown-container {
    display: flex;

    @include respond($break60) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__title {
    font-size: 28px;
    font-weight: 500;

    @include respond($break60) {
      padding-bottom: 50px;
    }
  }

  &__select {
    @include selectStyle;
  }
}

/*
  Past Exhibitions sidebar
*/
.exhibition-sidebar {
  @include respond($break90) {
    margin-top: -15px;
  }

  .year-nav {
    max-width: 225px;

    @include respond($break90) {
      margin: 0 auto;
    }

    .current-year {
      width: 100%;
      padding-left: 16px;
      font-family: $fontSmallCaps;
      font-size: 65px;
      font-weight: $light;
      line-height: 0.75;

      @include respond($break90) {
        width: 125px;
        height: 50px;
        padding-left: 0;
        margin: 0 auto;
        font-size: 50px;
        text-align: center;
      }
    }

    button {
      @include buttonUnstyled;
      position: absolute;
      top: 25%;
      display: none;
      font-size: 12px;

      &.prev {
        @include iconPre($arrowLeftLong);
        left: 0;
      }

      &.next {
        @include iconPost($arrowRightLong);
        right: 0;
      }

      @include respond($break90) {
        display: block;
      }
    }
  }

  // scoped for past exhibitions
  .filter-list {
    padding: 82px 0 0;
    background-color: $neutralWhite;

    @include respond($break90) {
      padding-top: 30px;
      padding-bottom: 30px;
      margin-top: 30px;
      border-top: 1px solid $neutral40;
      border-bottom: 1px solid $neutral40;
    }

    // Collapsed by default on mobile
    .full {
      max-height: unset;
      visibility: visible;
      opacity: 1;

      @include respond($break90) {
        max-height: 0;
        visibility: hidden;
        opacity: 0;
      }
    }

    .show-all {
      display: none;
      width: 100%;
      font-weight: $semibold;
      color: $neutralBlack;

      @include respond($break90) {
        display: block;
      }

      &::before {
        padding-right: 5px;
        margin-left: -$buttonIconPadding;
        content: '\002B';
      }
    }

    &.open {
      transition: min-height 0.15s $timing;

      .show-all {
        &::before {
          content: '\2212';
        }
      }

      .full {
        @include fadeIn($duration);
        transition-delay: 0.25s;
      }
    }

    &.closed {
      transition: min-height 0.2s $timing 0.25s;

      .full {
        transition:
          opacity $duration $timing,
          visibility $duration $timing;
      }
    }

    // Hidden, used to calculate sizing when box opens and closes
    .full-placeholder {
      position: absolute;
      visibility: hiddden;
      opacity: 0;
    }

    button {
      padding-left: $buttonIconPadding;

      &.active {
        &::before {
          width: $buttonIconPadding;
          padding-right: 6px;
          margin-left: -$buttonIconPadding;
          transform: translateY(1px);
        }
      }
    }
  }
}

/*
  Base styles shared by Exhibition, Event, Series, Collection, and Artwork Detail pages
*/
.detail {
  $detailPadding: 75px;

  .content-section {
    padding-top: $detailPadding;
    padding-bottom: $detailPadding;

    &.reduced-bottom-padding {
      padding-bottom: var(--sectionSpace30);
    }

    a {
      &.name-link {
        display: block;
        color: $neutralBlack;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .container-primary {
    @include respond($break110) {
      // Switch to containerFocus max-width at break
      max-width: calc(#{$containerFocusWidth} + (2 * var(--containerPadding)));
    }
  }

  .container-wide {
    max-width: calc(#{$containerWideWidth} + (2 * var(--sectionSpace30)));
    padding: 0 var(--sectionSpace30) 15px;

    @include respond($break110) {
      padding: 0;
      margin-bottom: -15px;
    }
  }

  .container-focus {
    flex: 1 1 auto;
    width: 100%;

    &:not(.content-rte) {
      @include respond($break110) {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  .flex-wrapper {
    display: flex;

    @include respond($break110) {
      flex-direction: column;
    }
  }

  .aside-left,
  .aside-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: var(--containerPadding);

    @include respond($break110) {
      align-items: center;
      width: auto;
      margin: 0 auto;
      text-align: center;
    }

    a {
      display: block;
      margin-top: var(--sectionSpace30);
      color: $neutralBlack;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .aside-left {
    order: -1;

    a {
      @include respond($break110) {
        margin-top: 0;
        margin-bottom: 35px;
      }
    }
  }

  .aside-right {
    text-align: right;

    @include respond($break110) {
      order: -2;
      text-align: center;

      a {
        margin-top: 0;
      }

      .add-button-wrapper {
        margin-top: calc(var(--sectionSpace30) / 2);
        margin-bottom: var(--sectionSpace30);
      }

      [data-behavior='OpenOverlay']:last-child {
        margin-bottom: var(--sectionSpace30);
      }
    }
  }

  .section-heading {
    padding-top: 15px;
    padding-bottom: $detailPadding;
    text-align: center;

    @include respond($break110) {
      padding-bottom: 60px;
    }
  }

  .label {
    @include labelPrimary;
    font-weight: $medium;
    line-height: 1.4;
  }

  .cta-wrapper {
    display: block;
    padding-top: 40px;
    text-align: center;

    .button-primary {
      text-decoration: none;
    }
  }

  // Scoping for specific Detail pages
  &.exhibition-detail {
    .chronological-list-secondary {
      .item:not(:last-child) .heading {
        @include respond($break70) {
          padding-bottom: 0;
          border-bottom: none;
        }
      }

      .time-for-small {
        @include respond($break70) {
          &::before {
            white-space: pre;
            content: '\0020|\0020';
          }
        }
      }

      .time-for-large {
        padding-right: 60px;
        font-size: 25px;
        letter-spacing: 0.016em;
      }
    }
  }

  &.event-detail {
    .chronological-list-secondary {
      .title {
        order: 0;
        padding-bottom: 0.556em;
        font-weight: $semibold;
      }

      .labels {
        order: -2;

        @include respond($break70) {
          padding-top: 7px;
        }
      }

      .label {
        &::before {
          content: '';
        }
      }
    }
  }

  &.artwork-detail {
    .detail-header {
      align-items: center;
      padding-bottom: 15px;
    }

    .content-section {
      padding-top: 0;
    }

    .aside-right a {
      margin-top: 0;
    }
  }
}

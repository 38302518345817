.featured-video {
  display: flex;
  align-items: center;

  &__title {
    font-size: 26px;
    font-weight: 500;
  }

  &__body {
    padding-top: 30px;
  }

  &__description {
    flex: 1;
    padding-left: 25px;
  }

  // This is a pre-existing non-BEM class that is being extended
  .teaser-body {
    flex: 1;
    padding-right: 25px;
  }

  // overwrite the margin bottom rule on all figure grid elements.
  // We don't need it in this custom setting
  figure {
    margin-bottom: 0;
  }

  &--vertical {
    display: block;
    max-width: 620px;
    margin: auto;

    .featured-video__description {
      padding-left: 0;
    }

    .teaser-body {
      padding-right: 0;
      padding-bottom: 30px;
    }
  }

  @include respond($break80) {
    display: block;

    .teaser-body {
      padding-bottom: 30px;
    }

    &__description {
      padding-left: 0;
    }

    .teaser-body {
      padding-right: 0;
    }
  }
}

$_tablet-break: 1320px;
$_mobile-break: 1000px;

.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $neutralWhite;

  > .container {
    display: flex;
    justify-content: space-between;

    @include respond($break160 + 40) {
      padding-right: 40px;
      padding-left: 40px;
    }

    @include respond($_mobile-break) {
      padding-right: var(--containerPadding);
      padding-left: var(--containerPadding);
    }
  }


  .header {
    display: flex;
    align-items: center;

    @include respond($_tablet-break) {
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .logo {
      padding-top: 4px;
      padding-right: 28px;

      @include respond($_tablet-break) {
        padding-top: 10px;
      }

      @include respond($_mobile-break) {
        padding-top: 6px;
      }
    }

    .logo svg {
      width: 124px;
      height: 19.28px;
      overflow: visible;

      @include respond($_tablet-break) {
        width: 120px;
        height: 18.66px;
      }

      @include respond($_mobile-break) {
        width: 110px;
        height: 17.09px;
      }

      .headroom--not-top & {
        width: 110px;
        height: 17.09px;
      }
    }

    .notice {
      font-size: 14px;
      font-weight: $medium;

      @include respond($_tablet-break) {
        margin-bottom: 0.125em;
      }
    }
  }

  .site-navigation {
    display: flex;
    flex-shrink: 0;
    align-items: center;

    a,
    [data-ref='activator'] {
      font-size: 18px;
      color: $neutralBlack;

      @include respond($_tablet-break) {
        font-size: 16px;
      }
    }

    .level-1 {
      @include listUnstyled;
      display: flex;
      padding-top: 46px;

      @include respond($_tablet-break) {
        padding-top: 21px;
      }

      @include respond($_mobile-break) {
        // Temporary mobile state
        display: none;
      }

      .headroom--not-top & {
        padding-top: 21px;
      }

      > li {
        &.active {
          > a {
            color: $neutralBlack;
          }
        }

        > a,
        [data-ref='activator'] {
          position: relative;
          display: block;
          padding: 0 20px 41px;
          color: $neutral80;
          cursor: pointer;

          @include respond($break110) {
            padding-right: 0.833em;
            padding-left: 0.833em;
          }

          @include respond($_tablet-break) {
            padding-bottom: 15px;
          }

          .headroom--not-top & {
            padding-bottom: 15px;
          }

          &::before {
            position: absolute;
            right: 20px;
            bottom: 0;
            left: 20px;
            display: block;
            height: 3px;
            content: '';
            background-color: $neutralBlack;
            opacity: 0;

            /* stylelint-disable max-nesting-depth */
            @include respond($break110) {
              right: 0.833em;
              left: 0.833em;
            }
            /* stylelint-enable */
          }

          &.open,
          &:hover {
            color: $neutralBlack;
            transition:
              color $duration $timing,
              padding $duration $timing;
          }

          &.open::before {
            opacity: 1;
            transition: opacity $duration $timing;
          }

          &.closed {
            transition:
              color $duration $timing,
              padding $duration $timing;
          }

          &.closed::before {
            transition: opacity $duration $timing;
          }
        }

        .search {
          font-size: 21px;
        }
      }
    }

    .level-2 {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 350px;
      visibility: hidden;
      background-color: $neutral20;
      opacity: 0;

      &.open {
        @include fadeIn(0.2s);

        li {
          opacity: 1;
        }
      }

      &.closed {
        @include fadeOut(0.3s);
        transition-delay: 0.2s;

        li {
          transition: opacity $duration $timing 0.2s;
        }
      }

      &.animating {
        li {
          transition: opacity $duration $timing;
        }
      }

      li {
        position: relative;
        opacity: 0;

        + li {
          margin-top: 1.056em;
        }
      }

      > ul {
        @include listUnstyled;
        display: inline-block;
        padding: 36px;

        &.open {
          @include fadeIn(0.2s);
        }

        &.columnar {
          display: flex;

          > li + li {
            padding-left: 80px;
            margin-top: 0;
          }
        }

        &.right {
          justify-content: flex-end;
          max-width: 1600px;
          margin: 0 auto;

          @include respond($break160) {
            margin-right: 37px;
          }
        }
      }
    }

    .level-3 {
      @include listUnstyled;

      li {
        font-weight: $book;

        &:first-child {
          font-weight: $medium;
        }
      }
    }

    .search button {
      @include buttonUnstyled;
      @include iconPre($magnify);
      padding: 0;
      margin-left: 0.571em;
      font-size: 21px;

      @include respond($_mobile-break) {
        width: 18px;
        font-size: 17px;
      }
    }

    .utility {
      @include listUnstyled;
      display: flex;
      padding-top: 6px;

      @include respond($_mobile-break) {
        padding: 20px 0 17px;
      }
    }

    .hamburger {
      display: none;
      margin-top: -2px; // NP
      margin-left: 21px;

      @include respond($_mobile-break) {
        display: flex;
      }

      button {
        @include buttonUnstyled;
        @include iconPre($hamburger);
        display: block;
        padding: 0;
        margin: auto;
        font-size: 12px;
      }
    }
  }
}

// Bump used to prevent fixed header from overlapping top content
// Size may change when announcements are built
.site-header-bump {
  height: $headerHeightTall;

  @include respond($_tablet-break) {
    height: $headerHeightShort;
  }
}

.staff-detail {
  width: 100%;
  max-width: calc(#{$containerBaseWidth} + 116px);
  padding-left: 116px;
  margin: 0 auto;

  @include respond($break100) {
    height: 100%;
    padding: 0;
  }

  figure {
    position: absolute;
    left: -290px;
    width: 234px;
    height: 234px;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 100%;

    @include respond($break100) {
      position: static;
      left: auto;
      width: 23.875vw;
      height: 23.875vw;
      margin: 0 auto;
    }

    @include respond($break60) {
      width: 155px;
      height: 155px;
    }
  }

  .wrapper {
    padding: 80px 250px 80px 170px;
    background-color: $neutral20;

    @include respond($break130) {
      padding-right: 18.288vw;
    }

    @include respond($break110) {
      padding: 80px 12vw;
    }

    @include respond($break100) {
      display: flex;
      flex-flow: column;
      padding: 50px;

      @include respond($break60) {
        padding-top: 20px;
      }

      .rel {
        order: 2;
      }

      .body {
        order: 3;
        font-size: 16px;
      }
    }
  }

  header {
    padding-bottom: 64px;

    @include respond($break100) {
      order: 1;
      padding-bottom: 48px;
      text-align: center;
    }
  }

  .name {
    padding-bottom: 0.4em;
    font-size: 50px;
    font-weight: $light;

    @include respond($break100) {
      padding-bottom: 25px;
      font-size: 32px;
    }

    &::after {
      display: inline-block;
      width: 40px;
      height: 2px;
      margin-left: 40px;
      content: '';
      background-color: $neutral40;

      @include respond($break100) {
        display: none;
      }
    }
  }

  a {
    display: inline-block;
    color: $neutralBlack;
  }

  .title {
    font-size: 18px;
    font-weight: $semibold;

    @include respond($break100) {
      font-size: 16px;
    }
  }

  .contact {
    padding-top: 23px;
    font-size: 17px;

    @include respond($break100) {
      font-size: 16px;
    }

    .email {
      @include iconPre($arrowRightLong);
      margin-right: 24px;

      &::before {
        margin-right: 5px;
        font-size: 10px;
        vertical-align: middle;
      }

      .hostname::before {
        content: '@';
      }
    }

    .phone {
      display: inline-block;
      font-weight: $book;
    }
  }

  .mobile-close {
    padding-bottom: 34px;
  }

  .button-primary {
    margin-top: 40px;
    text-decoration: none;

    @include respond($break100) {
      display: none;
    }
  }

  .bio {
    @include respond($break100) {
      padding-top: 40px;
    }
  }

  .button-mobile {
    @include buttonPrimary;
    display: none;

    @include respond($break100) {
      left: 50%;
      display: inline-block;
      margin-top: 40px;
      transform: translateX(-50%);
    }
  }
}

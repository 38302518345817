.list-by-year {
  .heading-primary {
    padding-bottom: 0.6em;
    text-align: center;
  }

  .year {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    @include respond($break90) {
      flex-direction: column;
    }

    + .year {
      margin-top: var(--sectionSpace70);

      @include respond($break90) {
        border-top: 1px solid $neutral40;
      }
    }
  }

  .list-heading {
    @include headingSecondary;
    order: 1;
    margin-right: -115px;
    font-family: $fontSmallCaps;
    font-size: 40px;
    font-weight: $light;

    @include respond($break90) {
      order: 0;
      width: 100%;
      padding-top: 30px;
      margin: 0;
      text-align: center;
    }

    @include respond($break60) {
      font-size: 32px;
    }

    &::before {
      display: inline-block;
      width: 35px;
      height: 1px;
      content: '';
      background-color: $neutralBlack;

      @include respond($break90) {
        display: none;
      }
    }
  }

  .event-list {
    @include listUnstyled;

    @include respond($break90) {
      width: 100%;
    }
  }

  .event {
    @include respond($break90) {
      margin-top: 5.376vw;
    }

    @include respond($break60) {
      margin-top: 35px;
    }

    + .event {
      margin-top: var(--sectionSpace70);

      @include respond($break90) {
        margin-top: 7.85vw;
      }

      @include respond($break60) {
        margin-top: 35px;
      }
    }

    .title {
      font-size: 28px;
      font-weight: $book;

      @include respond($break60) {
        font-size: 24px;
      }
    }

    .date {
      display: block;
      padding-top: 0.5em;
      font-size: 18px;
      font-weight: $book;

      @include respond($break60) {
        font-size: 16px;
      }
    }
  }
}

.image-grid {
  @mixin columnPadding($direction) {
    padding-#{$direction}: 60px;

    @include respond($break120) {
      padding-#{$direction}: 4.878vw;
    }

    @include respond($break90) {
      padding-#{$direction}: 3.2vw;
    }

    @include respond($break60) {
      padding-#{$direction}: 0;
    }
  }

  @mixin breakout($direction) {
    // TODO clean this up with variables in STACSS/_structure.scss
    .grid-item.breakout {
      margin-#{$direction}: calc(-1 * (var(--containerPadding) + 116.5px));

      @include respond($break160) {
        margin-#{$direction}: calc(-1 * (var(--containerPadding) + ((100vw - (1127px + (2 * var(--containerPadding)))) / 2)));
      }

      @include respond($break130) {
        margin-#{$direction}: calc(-1 * var(--containerPadding));
      }

      @include respond($break60) {
        margin-#{$direction}: 0;
      }

      @if $direction == 'left' {
        figcaption {
          text-align: right;
        }
      }
    }
  }

  @include containerPrimary;
  display: flex;
  justify-content: space-between;

  @include respond($break60) {
    display: block;
  }

  .column {
    width: 50%;

    @include respond($break60) {
      width: 100%;
      min-width: 100%;
    }

    &.width-40 {
      width: 36.113%;

      + .column {
        min-width: 63.887%;
      }
    }

    &.width-45 {
      width: 43.2%;

      + .column {
        min-width: 56.8%;
      }
    }

    &.width-55 {
      min-width: 56.8%;

      + .column {
        width: 43.2%;
      }
    }

    &.width-60 {
      min-width: 63.887%;

      + .column {
        width: 36.113%;
      }
    }

    &:first-child {
      @include columnPadding(right);
      @include breakout(left);
    }

    &:not(:first-child) {
      @include columnPadding(left);
      @include breakout(right);
    }

    + .column {
      @include respond($break60) {
        padding-top: 54px;
      }
    }

    // .grid-item variations that only work inside columns
    .grid-item {
      // TODO clean this up with variables in STACSS/_structure.scss
      &.breakout {
        width: calc(100% + var(--containerPadding) + 116.5px);

        @include respond($break160) {
          width: calc(100% + var(--containerPadding) + ((100vw - (1127px + (2 * var(--containerPadding)))) / 2));
        }

        @include respond($break130) {
          width: calc(100% + var(--containerPadding));
        }

        @include respond($break60) {
          width: 100%;
        }
      }

      &.space-top-full {
        margin-top: 280px;

        @include respond($break120) {
          margin-top: 22.764vw;
        }

        @include respond($break60) {
          margin-top: 0;
        }
      }

      &.space-top-half {
        margin-top: 140px;

        @include respond($break120) {
          margin-top: 11.382vw;
        }

        @include respond($break60) {
          margin-top: 0;
        }
      }
    }
  }

  .full-width {
    @include respond($break50) {
      margin-right: calc(var(--containerPadding) * -1);
      margin-left: calc(var(--containerPadding) * -1);
    }
  }

  .grid-item {
    display: block;
    width: 100%;
    color: $neutralBlack;
    cursor: pointer;

    @include respond($break60) {
      text-align: center;
    }

    &:hover {
      img {
        opacity: 0.6;
      }
    }

    &.align-center {
      text-align: center;
    }

    &.align-right {
      text-align: right;

      @include respond($break60) {
        text-align: center;
      }
    }

    &.callout {
      text-align: center;

      // Doesn't do anything unless inside callout
      .matte {
        padding: 93px var(--containerPadding) 89px;
        background-color: $neutral20;

        @include respond($break60) {
          padding: 0;
          background-color: transparent;
        }
      }

      &.narrow {
        // Slightly different for callouts
        figure {
          width: 100%;
          max-width: none;

          @include respond($break60) {
            width: 45vw;
            min-width: 320px;
          }

          @include respond($break50) {
            width: 100%;
            min-width: 0;
          }
        }

        img {
          max-width: 430px;

          @include respond($break90) {
            width: 45vw;
            max-width: 100%;
          }

          @include respond($break60) {
            width: 100%;
            max-width: none;
          }
        }
      }
    }

    &.narrow {
      figure {
        max-width: 430px;

        @include respond($break90) {
          width: 45vw;
          max-width: 100%;
        }

        @include respond($break60) {
          min-width: 320px;
        }

        @include respond($break50) {
          width: 100%;
          min-width: 0;
        }
      }
    }

    &.grid-heading {
      width: 66.667%;
      color: $neutral100;

      @include respond($break120) {
        width: 100%;
      }

      h1 {
        @include headingPrimary;
        font-size: 72px;
        line-height: 1.333;
        overflow-wrap: break-word;

        @include respond($break110) {
          font-size: 6.545vw;
        }

        @include respond($break60) {
          font-size: 32px;
        }
      }
    }

    + .grid-item {
      padding-top: 244px;

      @include respond($break120) {
        padding-top: 19vw;
      }

      @include respond($break60) {
        padding-top: 54px;
      }
    }

    figure {
      display: inline-block;
      width: 100%;
      line-height: 0;
    }

    img {
      width: 100%;
      height: auto;
      transition: opacity $duration $timing;
    }

    figcaption {
      text-align: left;

      @include respond($break60) {
        padding-top: 4px;
      }

      .title {
        padding-top: 6px;
      }
    }

    .title {
      padding-top: 1.25em;
      font-size: 28px;
      font-weight: $book;
      line-height: 1.179em;

      @include respond($break90) {
        font-size: 24px;
        line-height: 1.5em;
      }

      + .subtitle {
        padding-top: 0.5em;

        @include respond($break90) {
          padding-top: 0;
        }
      }
    }

    .subtitle {
      font-size: 18px;
      font-weight: $book;
      line-height: 1.4;

      @include respond($break90) {
        font-size: 16px;
      }
    }

    .caption {
      padding-top: 0.65em;
      font-size: 14px;
      font-weight: $book;
      line-height: 1.4;

      @include respond($break50) {
        padding-right: 10px;
        padding-left: 10px;
        text-align: center;
      }
    }
  }
}

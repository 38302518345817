.icon-notes {
  @include listUnstyled;
  display: flex;
  flex-direction: column;
  width: 172px;

  @include respond($break130) {
    flex-flow: row wrap;
    align-items: baseline;
  }

  .note {
    text-align: center;

    @include respond($break130) {
      width: 172px;
      padding-top: 60px;
    }

    + .note {
      padding-top: 60px;
    }
  }

  img {
    width: 106px;
    height: auto;
  }

  .caption {
    display: block;
    padding-top: 1.133em;
    font-size: 15px;
    font-weight: $book;
    line-height: 1.533;
    color: $neutral80;
  }
}

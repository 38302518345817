.staff-teaser {
  display: flex;
  color: $neutralBlack;

  figure {
    @include figureRound(234px);
  }

  .caption {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding-left: 55px;
  }

  .body {
    width: 100%;

    + .info {
      padding-top: 18px;
    }
  }

  .info {
    width: 100%;
  }

  .name {
    @include headingSecondary;
    padding-bottom: 19px;
  }

  .title {
    display: block;
    font-size: 18px;
    font-weight: $book;
    line-height: 1.667;
  }

  .contact {
    padding-top: 23px;
    font-size: 17px;

    .email {
      @include iconPre($arrowRightLong);
      display: inline-block;
      margin-right: 24px;
      color: $neutralBlack;

      &::before {
        margin-right: 5px;
        font-size: 10px;
        vertical-align: middle;
      }

      .hostname::before {
        content: '@';
      }
    }

    .phone {
      display: inline-block;
      font-weight: $book;
    }
  }

  .button-secondary {
    margin-top: 9px;
  }

  @include respond($break60) {
    flex-direction: column;

    figure {
      @include figureRound(33vw);
      margin: 0 auto;
    }

    .caption {
      padding-left: 0;
    }

    .body {
      padding-top: 30px;
    }

    .name {
      font-size: 24px;
    }

    .title {
      font-size: 16px;
    }

    .contact {
      font-size: 16px;
    }
  }

  // scoped for inside two-column CE
  // mainly, the breakpoint must come sooner
  .columns-2 & {
    figure {
      @include figureRound(163px);
    }

    .button-secondary {
      margin-top: 19px;
    }

    @include respond($break110) {
      flex-direction: column;

      figure {
        margin: 0 auto;
      }

      .caption {
        padding-left: 0;
      }

      .body {
        padding-top: 30px;
      }

      .name {
        font-size: 24px;
      }

      .title {
        font-size: 16px;
      }

      .contact {
        font-size: 16px;
      }
    }
  }

  &-primary {
    .caption {
      padding-left: 70px;

      @include respond($break60) {
        padding-left: 0;
      }

      .columns-2 & {
        @include respond($break110) {
          padding-left: 0;
        }
      }
    }
  }
}

// Component Appearance
// --------------------

// Appearance mixins contain primarily texture parameters: background-color, transparency, borders, etc. as well
// as some minor, internal structural properties where abstraction isn't required (to prevent over-abstracting)

// They may contain:
// Nested classes/elements with other structural or textural properties

// Appearance blocks should not contain typographic classes nor be dependent on the font-size from the element, unless
// it is as a non-dependent, comprehensive mixin (for now), or if the font size is being used for structural reasons (like the size of an icon-font glyph)

// Contents
// --------------------------
// - Imports
// - Prototypes
// - Utilities
// - Layout
// - Type
// - Images
// - Article Lists
// - Navigation Lists
// - Buttons/Links
// - Tables
// - Forms

// Basic utility patterns
// --------------------------------------------------------
// Visually Hidden, for skip links and screen-reader only elements
@mixin screenReaderText {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

// Image replacement, for icons and things with hidden accessibility text
@mixin imageReplacement {
  display: block;
  overflow: hidden;
  text-align: left;
  text-indent: -999em;
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
  direction: ltr;

  br {
    display: none;
  }
}

.screen-reader-text {
  @include screenReaderText;
}

// Utility
// --------------------------------------------------------
.bg-gray {
  background-color: $neutral20;
}

.bg-black {
  background-color: $neutral100;
}

// Buttons
// --------------------------------------------------------
@mixin buttonPrimary {
  @include labelPrimary;
  position: relative;
  display: inline-block;
  padding: 1.2em 29px 1em;
  font-weight: $semibold;
  line-height: 1;
  color: $neutralBlack;
  text-decoration: none;
  background-color: $neutralWhite;
  border: 1px solid $neutral100;
  transition: color $duration $timing, background-color $duration $timing;

  &:hover {
    color: $neutralWhite;
    background-color: $neutral100;
  }
}

@mixin buttonSecondary {
  position: relative;
  display: inline-block;
  padding: 0.722em 0;
  font-size: 18px;
  font-weight: $medium;
  color: $neutralBlack;
  text-decoration: none;
  letter-spacing: 0.029em;
  border-bottom: 2px solid $neutral100;

  @include respond($break60) {
    font-size: 16px;
  }
}

@mixin buttonTertiary {
  @include buttonUnstyled;
  @include labelPrimary;
  position: relative;
  font-weight: $semibold;

  &.plus {
    @include iconPre($plus);
    padding-left: 14px;

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -5px;
      font-size: 9px;
    }
  }
}

@mixin buttonQuaternary {
  @include buttonUnstyled;
  @include labelPrimary;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: $neutralBlack;
  background-color: $neutralWhite;
  border: 1px solid $neutral100;
  transition: background-color $duration $timing;

  &:hover {
    color: $neutralWhite;
    background-color: $neutral100;
  }
}

.button-primary {
  @include buttonPrimary;
}

.button-secondary {
  @include buttonSecondary;

  + .button-secondary {
    margin-left: 61px;
  }
}

.button-tertiary {
  @include buttonTertiary;
}

.no-scroll {
  overflow: hidden;
}

@mixin circledText {
  display: inline-block;
  width: 3.647em;
  height: 3.647em;
  padding-top: 1em;
  font-size: 17px;
  font-weight: $semibold;
  color: $neutralWhite;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  background-color: $neutral100;
  border-radius: 100%;

  &[href='#'] {
    pointer-events: none;
  }
}

.circled-text {
  @include circledText;
}

.circled-inline-text {
  @include respond($break60, min) {
    @include circledText;
    margin-left: 0.235em;
    vertical-align: baseline;
  }
}

@mixin overlayClose {
  @include buttonUnstyled;
  @include iconPre($xCircle);
  position: absolute;
  top: 45px;
  right: 37px;
  width: 87px;
  height: 87px;
  padding: 0;
  font-size: 87px;
  line-height: 1;

  &::before {
    position: relative;
  }

  @include respond($break60) {
    display: none;
  }
}

@mixin overlayCloseMobile {
  display: none;

  @include respond($break60) {
    @include labelPrimary;
    @include buttonUnstyled;
    position: static;
    display: block;
    padding: $buttonIconPadding;
    margin: 0 auto;

    &::after {
      display: inline-block;
      width: 40px;
      height: 2px;
      margin-bottom: 4px;
      margin-left: 12px;
      content: '';
      background: $neutral40;
    }
  }
}

// List Navs
// --------------------------------------------------------
// Social nav shared styles
.social-nav {
  ul {
    @include listUnstyled;
    display: flex;
    gap: 34px;
    align-items: center;

    @include respond($break80) {
      justify-content: center;
    }
  }

  a {
    display: inline-block;
    color: $neutralBlack;
    block-size: 20px;
    min-inline-size: 20px;
  }

  svg {
    block-size: 20px;
    inline-size: auto;
  }

  .facebook {
    inline-size: 20.4px;
    background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="UTF-8"%3F><svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 20.2 20.2"><path class="cls-1" d="M20.2,10.1C20.2,4.5,15.6,0,10.1,0S0,4.5,0,10.1s3.7,9.3,8.5,10v-7.1h-2.6v-2.9h2.6v-2.2c0-2.5,1.5-3.9,3.8-3.9s2.3.2,2.3.2v2.5h-1.3c-1.3,0-1.6.8-1.6,1.6v1.9h2.8l-.4,2.9h-2.3v7.1c4.8-.8,8.5-5,8.5-10Z"/></svg>');
    background-size: 20.4px 20.4px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .instagram {
    background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="UTF-8"%3F><svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 18.7 18.7"><defs><style> .cls-1 { fill-rule: evenodd; } </style></defs><path class="cls-1" d="M18.6,5.5c0-.8-.2-1.5-.4-2.3-.5-1.3-1.5-2.2-2.7-2.7-.7-.3-1.5-.4-2.3-.4-1,0-1.3,0-3.9,0s-2.9,0-3.9,0c-.8,0-1.5.2-2.3.4C2,1,1,2,.5,3.2.2,3.9,0,4.7,0,5.5c0,1,0,1.3,0,3.9s0,2.9,0,3.9c0,.8.2,1.5.4,2.3.5,1.3,1.5,2.2,2.7,2.7.7.3,1.5.4,2.3.4,1,0,1.3,0,3.9,0s2.9,0,3.9,0c.8,0,1.5-.2,2.3-.4,1.3-.5,2.2-1.5,2.7-2.7.3-.7.4-1.5.4-2.3,0-1,0-1.3,0-3.8s0-2.9,0-3.9ZM16.9,13.1c0,.6-.1,1.2-.3,1.7-.3.8-1,1.5-1.8,1.8-.6.2-1.1.3-1.7.3-1,0-1.3,0-3.8,0s-2.8,0-3.8,0c-.6,0-1.2-.1-1.7-.3-.8-.3-1.5-1-1.8-1.8-.2-.6-.3-1.1-.3-1.7,0-1,0-1.3,0-3.8s0-2.8,0-3.8c0-.6.1-1.2.3-1.7.3-.8,1-1.5,1.8-1.8.6-.2,1.1-.3,1.7-.3,1,0,1.3,0,3.8,0s2.8,0,3.8,0c.6,0,1.2.1,1.7.3.8.3,1.5,1,1.8,1.8.2.6.3,1.1.3,1.7,0,1,0,1.3,0,3.8s0,2.8,0,3.8ZM4.5,9.3c0-2.6,2.1-4.8,4.8-4.8s2.5.5,3.4,1.4c.9.9,1.4,2.1,1.4,3.4,0,2.6-2.1,4.8-4.8,4.8s-4.8-2.1-4.8-4.8ZM9.3,12.5c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1,3.1,1.4,3.1,3.1-.3,1.6-.9,2.2c-.6.6-1.4.9-2.2.9ZM14.3,5.5c.6,0,1.1-.5,1.1-1.1s-.5-1.1-1.1-1.1-1.1.5-1.1,1.1.5,1.1,1.1,1.1Z"/></svg>');
    background-size: 18px 17px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .youtube {
    inline-size: 24px;
    background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="UTF-8"%3F><svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 17.6 12.3"><path d="M17.2,1.9c-.1-.4-.3-.7-.6-1-.3-.3-.6-.5-1-.6-1.4-.4-6.9-.4-6.9-.4,0,0-5.5,0-6.9.4-.4.1-.7.3-1,.6-.3.3-.5.6-.6,1-.4,1.4-.4,4.2-.4,4.2,0,0,0,2.9.4,4.2.1.4.3.7.6,1,.3.3.6.5,1,.6,1.4.4,6.9.4,6.9.4,0,0,5.5,0,6.9-.4.4,0,.7-.3,1-.6.3-.3.5-.6.6-1,.4-1.4.4-4.2.4-4.2,0,0,0-2.9-.4-4.2ZM7,8.8V3.5l4.6,2.6-4.6,2.6Z"/></svg>');
    background-size: 24px 17px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

// Images
// --------------------------------------------------------
@mixin figureRound($size) {
  min-width: $size;
  max-width: $size;
  height: $size;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 100%;
}

// Transitions
// --------------------------------------------------------
@mixin basicReactFade($class: fade) {
  // Transitions
  &.#{$class}-enter {
    opacity: 0.01;
  }

  &.#{$class}-enter.#{$class}-enter-active {
    opacity: 1;
    transition: opacity 0.2s ease-in;
  }

  &.#{$class}-leave {
    opacity: 1;
  }

  &.#{$class}-leave.#{$class}-leave-active {
    opacity: 0.01;
    transition: opacity 0.2s ease-in;
  }
}

@mixin fadeIn($duration: 0.35s) {
  visibility: visible;
  opacity: 1;
  transition: opacity $duration $timing, visibility $duration $timing;
}

@mixin fadeOut($duration: 0.1s) {
  visibility: hidden;
  opacity: 0;
  transition: opacity $duration $timing, visibility $duration $timing;
}

// Shared Components
// --------------------------------------------------------
// CABS overlayPrimary if need be
@mixin overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: auto;
  overscroll-behavior-y: contain;
  visibility: hidden;
  background-color: $neutralWhite;
  opacity: 0;

  &.hidden {
    @include fadeOut;
  }

  &.visible {
    visibility: visible;
    opacity: 1;

    @include fadeIn;
  }

  &.react {
    @include basicReactFade;
  }

  &.staff-gray {
    padding-right: 8vh;
    padding-left: 8vh;

    @include respond($break60) {
      padding: 0;
      background-color: $neutral20;

      .content-section {
        padding-top: 0;
      }
    }
  }

  > .container {
    position: relative;
  }
}

@mixin splashImage {
  @include container;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 480px;
  min-height: 250px;
  background-position: 50% 50%;
  background-size: cover;

  @include respond($break100) {
    align-items: flex-start;
    height: 48.98vw;
  }

  &.loaded::before {
    opacity: 0;
  }

  img[data-ref='image'],
  img[data-ref='mobileImage'] {
    // image src is pulled into background image src for .hero
    // with data-behavior="ResponsiveBackgroundImage"
    display: none;
  }
}

@mixin selectStyle {
  display: flex;
  align-items: center;
  min-width: 180px;
  padding: 0.722em 0;
  margin: 0 20px;
  border-bottom: 1px solid $neutral100;

  @include respond($break60) {
    display: block;
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
  }

  select {
    position: relative;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 140 140' width='15' height='15' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0 top 50%;
    border: none;
    outline: none;
    appearance: none;
  }
}

@use 'sass:math';

.columnar-text {
  $columnGap: 46px;
  $columnWidth: math.div($containerWideWidth, 3) - $columnGap * 2;

  header:not([class]) {
    text-align: center;
  }

  .heading {
    @include headingPrimary;
  }

  .section + .section {
    padding-top: var(--sectionSpace100);
  }

  .subheading {
    @include headingSecondary;
    padding-bottom: var(--sectionSpace70);

    @include respond($break60) {
      padding-bottom: var(--sectionSpace30);
    }
  }

  .body {
    columns: $columnWidth 3;
    column-gap: $columnGap;

    &--two-col {
      $width: $containerWideWidth * 0.5 - $columnGap * 2;
      columns: $width 2;
      column-gap: $columnGap;
      margin-top: var(--sectionSpace30);
    }

    ul {
      @include listUnstyled;
    }

    li {
      line-height: 1.667;
      break-inside: avoid;
    }
  }
}

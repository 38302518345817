// CABS Filter list primary if there are other similar instances
.filter-list {
  header {
    border-bottom: 1px solid $neutral40;

    h4 {
      @include labelPrimary;
      padding-bottom: 1.267em;
      font-weight: $semibold;
    }
  }

  ul {
    @include listUnstyled;
  }

  li {
    padding-top: 17px;
  }

  button {
    @include buttonUnstyled;
    @include labelPrimary;
    display: block;
    font-size: 15px;
    font-weight: $medium;
    color: $neutral60;
    text-transform: uppercase;
    letter-spacing: 0.089em;

    @include respond($break90) {
      margin: 0 auto;
    }

    &:hover {
      font-weight: $semibold;
      color: $neutralBlack;
    }

    &.active {
      @include iconPre($xCircleCut);
      font-weight: $semibold;
      color: $neutralBlack;

      &::before {
        color: inherit;
      }

      &.all {
        &::before {
          color: transparent;
        }
      }
    }
  }
}

.read-more {
  min-height: 0;
  overflow: hidden;
  transition: min-height 0.15s $timing;

  &.open {
    .extra {
      @include fadeIn($duration);
      transition-delay: 0.25s;
    }

    .activator {
      @include fadeOut($duration);
      transition-delay: 0.25s;
    }
  }

  .snippet {
    padding-bottom: var(--sectionSpace30);
  }

  .activator {
    @include buttonUnstyled;
    position: absolute;
    display: block;

    // height: 26px;
    font-size: 17px;
    font-weight: $medium;
    opacity: 1;

    @include iconPre($plus);

    &::before {
      margin-right: 5px;
      font-size: 15px;
      vertical-align: middle;
    }
  }

  .placeholder {
    position: absolute;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
  }

  .extra {
    height: 0;
    visibility: hidden;
    opacity: 0;
  }
}

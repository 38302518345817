// Component Structure
// -------------------

// Structure-only mixins (and maybe utility classes), with parameters for margins, padding, position, display, etc.

// They may contain:
// Nested classes/elements that have structural parameters only

// NB: Containers that are modular regardless of context can use em units
// Structure should not be dependent on typography styles but font-size CAN be used as a structural style if necessary
// for scaling

// Utilities
// --------------------------------------------------------
.rel {
  position: relative;
}

.overflow-scroll {
  overflow: scroll;
}

// Layout
// --------------------------------------------------------
@mixin container {
  max-width: $containerMaxWidth;
  margin: 0 auto;
}

@mixin containerPrimary {
  max-width: calc(#{$containerBaseWidth} + (2 * var(--containerPadding)));
  padding-right: var(--containerPadding);
  padding-left: var(--containerPadding);
  margin: 0 auto;
}

@mixin containerFocus {
  max-width: calc(#{$containerFocusWidth} + (2 * var(--containerPadding)));
  padding-right: var(--containerPadding);
  padding-left: var(--containerPadding);
  margin: 0 auto;
}

.container {
  @include container;
}

.container-primary {
  // Lives inside a .container
  @include containerPrimary;
}

.container-wide {
  max-width: calc(#{$containerWideWidth} + (2 * var(--containerPadding)));
  padding-right: var(--containerPadding);
  padding-left: var(--containerPadding);
  margin: 0 auto;
}

.container-focus {
  @include containerFocus;
}

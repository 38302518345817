// Global z-index
.site-header {
  z-index: 125;
}

.header-hamburger {
  z-index: 100;
}

.page-content-wrapper .loader {
  z-index: -50;
}

.overlay,
.slideshow {
  z-index: 200;

  .close {
    z-index: 100;
  }
}

.menu-open .link-list {
  z-index: 75;
}

.hero-full-wrapper {
  z-index: 50;

  .scroll-capture {
    z-index: 250;
  }

  header {
    z-index: 200;
  }

  .cta,
  .scroll-hint {
    z-index: 300;
  }
}

.collections-nav {
  text-align: center;

  .wrapper {
    position: relative;
    padding-top: 54px;
    padding-bottom: var(--sectionSpace70);
  }

  [data-ref='image'],
  .fill {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  [data-ref='image'] {
    background-position: 50% 50%;
    background-size: cover;
    opacity: 0;
    transition: opacity 0.4s ease;

    &.active {
      opacity: 1;
    }
  }

  .fill {
    background-color: $neutralWhite;
    opacity: 0.7;
  }

  ul {
    @include listUnstyled;

    // pushes to front of stacking context
    position: relative;
  }

  li {
    @include headingPrimary;
    line-height: 1;

    @include respond($break90) {
      font-size: 5.37vw;
    }

    @include respond($break60) {
      font-size: 24px;
    }
  }

  a {
    display: inline-block;
    padding-top: 0.36em;
    padding-bottom: 0.36em;
    color: $neutralBlack;

    &:hover {
      .name {
        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          content: '';
          background-color: $neutralBlack;
        }
      }
    }
  }

  .name {
    position: relative;
    display: block;
    padding: 0.26em 0.5em;
  }

  // If followed by a Link Group CE
  + .content-section > .link-group {
    margin-top: -50px;
    margin-bottom: -30px;

    @include respond($break60) {
      margin-top: -35px;
      margin-bottom: 32px;
    }
  }
}

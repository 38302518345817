@use 'sass:math';

.hero-slider {
  $aspectRatio: math.div(2, 1);

  .flickity-viewport {
    transition: height $duration;
  }

  &__slide {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
    min-height: auto;
    overflow: hidden;
  }

  &__picture {
    position: relative;
    width: 100%;
    padding-top: math.div(100%, $aspectRatio);
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-right: var(--containerPadding, 120px);
    padding-left: var(--containerPadding, 120px);
    text-align: center;
  }

  &__heading {
    font-size: 72px;
    font-weight: $light;
    line-height: 1.333;
    color: $neutralBlack;

    @include respond($break110) {
      font-size: 6.545vw;
    }

    @media all and (max-height: 750px) and (min-aspect-ratio: 3/2) { /* stylelint-disable-line scss/operator-no-unspaced, scss/media-feature-value-dollar-variable */
      font-size: 9.6vh;
    }

    @include respond($break50) {
      font-size: 32px;
    }

    &:only-child {
      @include respond($break100, min) {
        position: relative;
        top: -0.12em;
      }
    }

    &:not(:only-child) {
      margin-bottom: 0.714em;
    }

    &.white {
      color: $neutralWhite;
    }
  }

  .hero-caption {
    width: 100%;
  }

  // Style items from library
  .flickity-button {
    @include buttonUnstyled;
    position: absolute;
    top: 400px;
    display: flex;
    width: 61px;
    height: 61px;
    background-color: transparentize($neutralWhite, 0.1);
    border: 1px solid $neutralBlack;
    transition: opacity 0.3s $timing;

    @include respond($containerMaxWidth) {
      top: 24vw;
    }

    @include respond($break60) {
      display: none;
    }

    &:disabled {
      opacity: 0;
    }

    &.previous {
      margin-left: 25px;

      @include iconPre($arrowLeftLong);

      &::before {
        line-height: 56px;
      }
    }

    &.next {
      margin-right: 25px;

      @include iconPost($arrowRightLong);

      &::after {
        line-height: 56px;
      }
    }

    &::before,
    &::after {
      display: block;
      margin: auto;
      font-size: 13px;
    }

    svg {
      display: none;
    }
  }
}

.search-form {
  text-align: center;

  input[type='text'] {
    @include inputUnstyled;
    width: $containerFocusWidth;
    max-width: 100%;
    margin-bottom: 0.76em;
    font-size: 50px;
    font-weight: $light;
    text-align: center;

    @include respond($break100) {
      font-size: 5.102vw;
    }

    @include respond($break60) {
      font-size: 32px;
    }

    &::placeholder {
      color: $neutralBlack;
    }
  }

  .search-overlay & {
    padding-top: 16.393vh;

    @include respond($break60) {
      padding-top: calc(16.393vh - 160px);
    }
  }

  .heading-secondary {
    padding-bottom: 6px;
  }
}

.search-overlay {
  background-color: transparentize($neutralWhite, 0.07);

  .mobile-close {
    padding-top: 120px;
  }

  .search-open & {
    @include fadeIn;
  }

  .menu-closed & {
    @include fadeOut;
  }

  .container-primary {
    text-align: center;
  }

  .submit {
    @include buttonUnstyled;
    @include iconPre($arrowRightCircleLarge);
    font-size: 77px;
  }
}

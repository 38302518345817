.site-footer {
  .signup {
    @include container;
    padding: 74px 46px 112px;
    text-align: center;
    background-color: $neutral100;

    @include respond($break100) {
      padding-bottom: 11.428vw;
    }

    @include respond($break50) {
      padding-top: 57px;
      padding-bottom: 47px;
      text-align: left;
    }

    .prompt,
    &__prompt {
      font-size: 18px;
      font-weight: $book;
      line-height: 1.778em;
      color: $neutralWhite;

      @include respond($break50) {
        font-size: 16px;
      }
    }

    &__link {
      display: inline-grid;
      grid-template: '. link-text link-icon' auto / 50px auto 50px;
      grid-gap: 0.5em;
      align-items: center;
      margin-top: 43px;
      font-size: clamp(38px, 5.744vw, 50px);
      font-weight: $light;
      color: $neutralWhite;

      @include respond($break50) {
        grid-template: 'link-text link-icon' auto / auto 40px;
        margin-top: 38px;
      }

      &:hover,
      &:focus-visible {
        .signup__link-text {
          border-color: $neutralWhite;
        }

        .signup__link-icon {
          opacity: 1;
          transform: translateX(15%);
        }
      }
    }

    &__link-text {
      display: inline-block;
      grid-area: link-text;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba($neutralWhite, 0.6);
      transition: border-color $duration $timing;
    }

    &__link-icon {
      @include iconPre($arrowRightCircleLarge);
      grid-area: link-icon;
      opacity: 0.6;
      transition: opacity $duration $timing, transform $duration ease-out;

      &::before {
        margin-top: 10px;
        font-size: 50px;
        -moz-osx-font-smoothing: auto;
        -webkit-font-smoothing: auto;

        @include respond($break90) {
          margin-top: 0;
        }

        @include respond($break65) {
          font-size: 40px;
        }
      }
    }

    .input {
      position: relative;
      display: inline-block;
      margin-top: 43px;

      @include respond($break50) {
        width: 80%;
        margin-top: 38px;
      }

      input[type='email'] {
        width: 10.84em;
        padding: 0 0 10px;
        font-size: 50px;
        font-weight: $light;
        color: $neutralWhite;
        text-align: center;
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid rgba($neutralWhite, 0.6);
        border-radius: 0;
        outline: 0;
        appearance: none;

        @include respond($break100) {
          width: 11.7em;
          font-size: 5.102vw;
        }

        @include respond($break50) {
          width: 100%;
          font-size: 5.744vw;
          text-align: left;
        }

        &::placeholder {
          color: $neutralWhite;
          opacity: 1;
        }
      }

      .submit {
        @include buttonUnstyled;
        @include iconPre($arrowRightCircleLarge);
        position: absolute;
        top: 50%;
        left: 107%;
        opacity: 0.6;
        transform: translateY(-50%);

        @include respond($break90) {
          top: 0;
          transform: translateY(0);
        }

        &::before {
          margin-top: 10px;
          font-size: 50px;
          color: rgba($neutralWhite, 0.6);
          -moz-osx-font-smoothing: auto;
          -webkit-font-smoothing: auto;
          transition: color $duration $timing;

          @include respond($break90) {
            margin-top: 0;
          }

          @include respond($break65) {
            font-size: 40px;
          }
        }

        &:hover::before {
          color: rgba($neutralWhite, 1);
        }
      }
    }
  }

  .container {
    padding-bottom: 75px;
    background-color: $neutral20;

    @include respond($break80) {
      padding-top: 47px;
      padding-bottom: 45px;
    }
  }

  .site-navigation {
    @include containerPrimary;
    padding-top: 83px;
    padding-bottom: 0;
    margin: 0 auto;

    @include respond($break80) {
      display: none;
    }

    a {
      display: block;
      color: $neutralBlack;
    }

    .level-1 {
      @include listUnstyled;
      display: flex;
      justify-content: space-between;

      > li {
        @include respond($break80) {
          + li {
            padding-left: 1em;
          }
        }

        > a {
          padding-bottom: 0.778em;
          font-size: 18px;
        }
      }
    }

    .level-2 {
      @include listUnstyled;
      margin-bottom: 33px;

      > li {
        a {
          font-size: 16px;
          font-weight: $book;
        }

        + li {
          margin-top: 0.813em;
        }
      }
    }
  }

  .colophon {
    @include containerPrimary;
    display: grid;
    grid-template:
      'copyright . address' auto
      'social-nav . aam-logo' auto / auto 1fr auto;
    row-gap: 26px;
    align-items: center;
    margin: 20px auto 0;

    @include respond($break80) {
      grid-template:
        'address' auto
        'social-nav' auto
        'copyright' auto
        'aam-logo' auto / 100%;
      row-gap: 45px;
    }

    p {
      margin: 0;
      font-size: 15px;
      font-weight: $book;

      @include respond($break80) {
        font-size: 16px;
        text-align: center;
      }

      &.copyright {
        grid-area: copyright;
      }

      &.address {
        grid-area: address;
        text-align: right;

        @include respond($break80) {
          text-align: center;
        }
      }

      a {
        color: $neutralBlack;
      }

      span {
        @include respond($break80) {
          display: block;
        }

        &:first-of-type {
          @include respond($break80) {
            padding-bottom: 25px;
            font-size: 32px;
            font-weight: $light;
          }
        }
      }
    }
  }

  .social-nav {
    grid-area: social-nav;
    align-self: start;
  }

  .aam-logo {
    grid-area: aam-logo;
    justify-self: flex-end;

    @include respond($break80) {
      justify-self: center;
      width: 80px;
      height: 80px;
    }
  }
}

.content-rte,
.content-default {
  @include copyPrimary;

  // Headings
  // --------------------------
  // CABS heading primary
  h1,
  h2 {
    @include headingPrimary;
    padding-bottom: var(--sectionSpace70);
  }

  // CABS heading secondary
  h3 {
    @include headingSecondary;
    padding-bottom: var(--sectionSpace30);

    &.padded-bottom {
      padding-bottom: var(--sectionSpace70);
    }
  }

  // CABS heading tertiary
  h4 {
    padding-bottom: var(--sectionSpace70);
    margin: 0;
    font-size: 15px;
    font-weight: $medium;
    text-transform: uppercase;
    letter-spacing: 0.089em;
  }

  // Copy
  // --------------------------
  .lead {
    font-size: 28px;
    line-height: 1.571em;

    @include respond($break60) {
      font-size: 24px;
      line-height: 1.5em;
    }
  }

  p {
    line-height: 1.667;

    &.copy-small {
      font-size: 85%;
    }

    + h3,
    + p,
    + ul,
    + ol {
      margin-top: var(--sectionSpace30);
    }

    + .content-button-secondary {
      margin-top: var(--sectionSpace70);
    }
  }

  hr {
    margin-top: var(--sectionSpace30);
    margin-bottom: var(--sectionSpace30);
  }

  // Lists
  ul,
  ol {
    padding-left: 17px;
    margin-top: 0;
    margin-bottom: 0;

    + h3,
    + p,
    + ul,
    ol {
      margin-top: var(--sectionSpace30);
    }
  }

  li {
    + li {
      padding-top: 1em;
    }
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  // Buttons
  // CABS Button Primary
  .content-button-primary {
    @include buttonPrimary;
    margin: 0 12px;

    + .content-button-primary {
      margin-left: 18px;
    }
  }

  // CABS Button Secondary
  .content-button-secondary {
    @include buttonSecondary;
    margin: 0 12px;

    + .content-button-secondary {
      margin-left: 18px;
    }
  }

  .content-circled-text {
    @include circledText;
  }

  // RTE link with download
  .link-has-download {
    @include iconPre($download);
    display: block;
    margin-top: var(--sectionSpace30);
    text-decoration: none;

    &::before {
      margin-right: 0.833em;
      font-size: 1.5em;
      vertical-align: middle;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    max-width: 100%;
  }
}

.text-thumb-block {
  display: flex;

  & + & {
    margin-top: var(--sectionSpace30);
  }

  &__text {
    flex-grow: 1;
    margin-left: var(--sectionSpace30);
  }

  &__image {
    flex-shrink: 0;
    align-self: flex-start;
    order: -1;
    height: auto;
  }
}

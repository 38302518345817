.fold-box {
  padding: 23px 24px 37px 32px;
  background-color: $neutral20;

  button {
    padding: 0;
    text-align: start;
    background: none;
    border: 0;
    inline-size: 100%;
  }

  .header {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
    margin-block-end: 0.375rem;
    text-wrap: balance;
    cursor: pointer;

    h4 {
      font-size: 18px;
      font-weight: $semibold;
    }

    .toggle {
      @include iconPre($plus);
      display: flex;

      &.open {
        @include iconPre($minus);
        font-size: 2px;
        cursor: pointer;
      }
    }
  }

  .teaser {
    display: block;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-inline-size: 530px;
    }

    &[aria-hidden='true'] {
      display: none;
    }
  }

  .rel {
    max-inline-size: 530px;
  }

  &[open] {
    .teaser {
      display: none;
    }

    .toggle {
      @include iconPre($minus);
      font-size: 2px;
      cursor: pointer;
    }
  }
}

.alert-overlay {
  background-color: transparentize($neutralWhite, 0.07);

  &--green {
    background-color: transparentize($green, 0.07);
  }

  &--yellow {
    background-color: transparentize($yellow, 0.07);
  }

  &--red {
    background-color: transparentize($red, 0.07);
  }

  .alert-container {
    max-width: calc(1042px + (2 * var(--containerPadding)));
    padding-top: 65px;
    padding-right: var(--containerPadding);
    padding-left: var(--containerPadding);
    margin: 0 auto;
  }

  .wrapper {
    min-height: calc(100vh - 254px);
    padding: 86px;
    background-color: $neutral20;

    @include respond($break100) {
      padding: 86px 8.775vw;
    }

    @include respond($break70) {
      padding: 66px;
    }
  }

  .alert-copy {
    @include copyPrimary;
  }

  .button-primary {
    margin-top: 96px;
  }

  .heading-primary {
    padding-bottom: 0;

    @include respond($break100) {
      font-size: 5.102vw;
    }

    @include respond($break70) {
      font-size: 40px;
    }

    + .alert-copy {
      padding-top: 74px;
    }
  }
}

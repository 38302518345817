.key-value-table {
  width: 100%;

  tr {
    td {
      padding: 0.111em 0;
      font-size: 18px;
      font-weight: $book;
      vertical-align: baseline;

      &.note {
        padding-top: 20px;
        font-size: 14px;
        color: $neutral100;
      }
    }

    td:nth-child(2) {
      padding-left: 23px;
    }

    .pad-top {
      padding-top: 22px;
    }
  }

  // scoped for homepage calendar component
  .mini-calendar & {
    tr:not(:first-child) {
      td:last-child {
        position: relative;

        &::before {
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 1px;
          content: '';
          background-color: $neutral40;
        }
      }
    }

    tr {
      &:first-child {
        td {
          padding-top: 0;
        }

        td:last-child > :first-child {
          margin-top: 0;
        }
      }

      td {
        position: relative;
        padding: 00;
        font-weight: $book;

        @include respond($break50) {
          font-size: 17px;
        }

        &:first-child {
          width: 5em;
          text-align: right;
        }

        &:last-child {
          padding-left: 37px;
        }

        > :first-child {
          margin-top: 16px;
        }

        > :last-child {
          margin-bottom: 15px;
        }
      }
    }
  }

  // scoped for hours & prices CE
  .visit-table & {
    tr {
      td {
        font-weight: $book;

        @include respond($break80) {
          padding-top: 0.375em;
          padding-bottom: 0.375em;
          font-size: 16px;
        }

        &:nth-child(2) {
          font-weight: $semibold;

          @include respond($break80) {
            font-weight: $book;
            text-align: right;
          }
        }
      }
    }
  }
}

.exhibition-list {
  @include listUnstyled;
  max-width: $containerFocusWidth;
  margin-left: 6vw;

  @include respond($break90) {
    margin-left: 0;
  }

  .item {
    padding-top: 50px;

    @include respond($break90) {
      padding-top: 5.376vw;
    }

    @include respond($break60) {
      padding-top: 35px;
    }

    + .item {
      padding-top: 73px;

      @include respond($break90) {
        padding-top: 7.85vw;
      }

      @include respond($break60) {
        padding-top: 35px;
      }
    }
  }

  .title {
    font-size: 28px;
    font-weight: $book;
    line-height: 1.5;

    @include respond($break60) {
      font-size: 24px;
    }
  }

  .date {
    display: block;
    padding-top: 0.6em;
    font-size: 18px;
    font-weight: $book;

    @include respond($break60) {
      font-size: 16px;
    }
  }
}

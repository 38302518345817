.page-content-wrapper {
  .loader {
    position: absolute;
    right: 0;
    left: 0;
    padding-top: var(--sectionSpace70);
    text-align: center;
  }

  .page-content {
    transition: opacity 0.35s $timing;

    &.loading {
      opacity: 0.3;
    }
  }
}

// Component Typography and Prototypes
// -----------------------------------

// Template typography mixins/prototypes and utility classes that can be extended into content classes.
// Typographic classes can contain some structural (not textural) design where it does not require abstraction,
// and textural classes dealing with typography (color, text-decoration, pseudo elements that modify text, etc).

// They may contain:
// Nested classes or elements with typographic/basic structural modifications only, for instance a header
// with <span> classes inside.

// These should not contain classes/prototypes to handle icon fonts. Those should be handles in a separate icons partial.

// Utilities
// --------------------------------------------------------
.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

@mixin fontSC {
  font-family: $fontSmallCaps;
}

// Headings
// --------------------------------------------------------
@mixin headingPrimary {
  font-size: 50px;
  font-weight: $light;

  @include respond($break60) {
    font-size: 32px;
  }
}

.heading-primary {
  @include headingPrimary;
  padding-bottom: var(--sectionSpace70);
  margin: 0;
  line-height: 1.2;
}

@mixin headingSecondary {
  padding-bottom: calc(var(--sectionSpace30) - (28px * 1.571 - 28px));
  margin: 0;
  font-size: 28px;
  font-weight: $book;
  line-height: 1.571;

  @include respond($break60) {
    font-size: 24px;
  }
}

.heading-secondary {
  @include headingSecondary;
}

.heading-tertiary {
  padding-bottom: var(--sectionSpace30);
  margin: 0;
  font-size: 24px;
  font-weight: $book;
  line-height: 1.2;
}

.heading-quarternary {
  padding-bottom: var(--sectionSpace30);
  margin: 0;
  font-size: 18px;
  font-weight: $book;
  line-height: 1.2;
  text-transform: uppercase;
}

// Copy
// --------------------------------------------------------

@mixin copyPrimary {
  font-size: 18px;
  font-weight: $book;

  @include respond($break60) {
    font-size: 16px;
  }
}

// Labels
// --------------------------------------------------------
@mixin labelPrimary {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 0.089em;
}

.upcoming {
  color: $neutralWhite;

  .wrapper {
    @include containerPrimary;
    display: flex;
    justify-content: flex-start;

    @include respond($break110) {
      flex-direction: column;
    }
  }

  header {
    @include respond($break110) {
      text-align: center;
    }
  }

  .heading {
    font-size: 72px;
    font-weight: $light;

    @include respond($break110) {
      font-size: 52px;
    }

    @include respond($break60) {
      font-size: 32px;
    }
  }

  // overrides to base class styles
  .exhibition-list {
    max-width: 500px;
    margin-left: 10vw;

    @include respond($break110) {
      max-width: unset;
      margin-left: 0;
    }

    .item {
      padding-top: 24px;

      @include respond($break110) {
        padding-top: 60px;
      }

      .item {
        padding-top: 60px;
      }
    }

    a {
      color: $neutralWhite;
    }

    .title {
      line-height: 1.167;
    }

    .date {
      padding-top: 0.55em;
    }
  }
}

.share-overlay {
  background-color: rgba($neutralWhite, 0.9);

  .container-primary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .wrapper {
    position: relative;
    width: 100%;
    max-width: $containerFocusWidth;
    padding: 4vw 5vw 5vw;
    background-color: $neutral20;

    @include respond($break60) {
      padding-top: 2.5vw;
      padding-bottom: 7.5vw;
    }

    .close {
      top: -43px;
      right: -43px;
    }
  }
}

.chronological-list-primary {
  @include listUnstyled;

  > li {
    display: flex;
    align-items: baseline;

    + li {
      padding-top: 0;

      @include respond($break60) {
        padding-top: 18px;
      }
    }

    .body {
      margin-bottom: 26px;
      border-bottom: 1px solid $neutral40;
    }
  }

  .time {
    min-width: 77px;
    font-size: 18px;
    font-weight: $book;
    text-align: right;

    @include respond($break100) {
      // Also make one inside body
      @include labelPrimary;
      display: none;
    }

    @include respond($break100) {
      min-width: 0;
      font-weight: $medium;
      text-align: left;
    }

    + .body {
      margin-left: 40px;

      @include respond($break100) {
        margin-left: 0;
      }
    }

    + .category,
    + .subtype {
      @include respond($break100) {
        margin-left: 10px;
      }
    }
  }

  // Can be a link or div
  .body {
    position: relative;
    width: 100%;
    padding-bottom: 27px;
    margin-left: 117px;
    color: $neutralBlack;

    @include respond($break100) {
      padding-left: 0;
    }

    .label {
      @include labelPrimary;
      display: flex;

      .time {
        display: none;
        padding-bottom: 0.733em;

        @include respond($break100) {
          display: inline-block;
        }

        @include respond($break60) {
          padding-bottom: 0.4em;
        }
      }

      .subtype {
        font-weight: $semibold;

        &::after {
          display: inline;
          padding-right: 2px;
          padding-left: 2px;
          font-weight: $book;
          content: '/';
        }
      }

      .category {
        padding-bottom: 0.733em;

        @include respond($break60) {
          padding-bottom: 0.4em;
        }
      }
    }

    .title {
      padding-bottom: 0.556em;
      font-size: 18px;
      font-weight: $semibold;
      line-height: 1.25;

      @include respond($break60) {
        font-size: 16px;
      }
    }

    .content-default {
      margin-bottom: -5px; // NP

      + figure {
        padding-top: 38px; // NP
      }
    }

    figure {
      width: 100%;
      padding-top: 33px;

      img {
        max-width: 100%;
        height: auto;
      }

      + .actions {
        padding-top: 1.05em;
      }
    }

    .actions {
      float: right;
      margin-right: 0;

      @include respond($break100) {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        float: none;

        > :first-child {
          margin-right: 1.25em;
        }
      }
    }
  }

  .add-button-wrapper {
    margin-top: 8px;

    &.mobile {
      display: none;

      @include respond($break100) {
        display: inline-block;
      }

      .addeventatc {
        top: 0;
        font-size: 22px;
      }
    }
  }
}

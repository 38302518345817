.video-player {
  width: 100%;
  max-width: calc(#{$containerBaseWidth} + 116px);
  padding: var(--sectionSpace100);
  margin: 0 auto;
  background-color: #f3f4f5;

  @include respond($break110) {
    padding: 10vw;
  }

  @include respond($break60) {
    padding-top: 6px;
  }

  > div {
    position: relative;
    width: 100%;
    max-width: 100%;
    padding-top: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
